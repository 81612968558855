.toast-modal {
  background-color: white;
  width: 330px;
  min-height: 350px;
  display: flex;
  border-radius: 12px !important;
  flex-direction: column;
  align-items: center;
  //   border: 1px solid;
  border-radius: 5px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  i {
    margin-top: 40px;
    font-size: 60px;
    margin-bottom: 40px;
    color: red;
  }
  .heading {
    font-size: 26px;
    color: var(--text-color-primary);
  }
  .message {
    font-size: 16px;
    color: var(--color-text-gray);
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
  }

  .button {
    margin-top: auto;
    width: 100%;
    background-color: red;
    color: white;
    height: 60px;
    border: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
.error-alert-overlay {
  background: none !important;
}
.fa-eye {
  z-index: 0 !important;
}
.react-confirm-alert-overlay {
  z-index: 9999 !important;
}
