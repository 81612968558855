.top-navigation-private {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.7);
  nav.upper-nav-private.navbar.navbar-expand-md.navbar-light {
    padding: 0px;
  }

  .upper-nav-private {
    a.navbar-brand {
      color: white !important;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
    a.nav-link {
      color: rgba(255, 255, 255, 0.7) !important;
      font-weight: 500;
      font-size: 14px;
    }
    a.nav-link:hover {
      color: #ee7d33 !important;
    }
    span {
      color: rgba(255, 255, 255, 0.7);
      padding-right: 10px;
    }
    button {
      color: rgba(255, 255, 255, 0.7);
      // padding: 0px 10px 0px 0px;
    }
    .contact-num-private {
      button {
        padding: 0px 10px 0px 0px;
        cursor: pointer;
      }
    }
    a:hover span {
      color: #ee7d33 !important;
    }
    ul.ml-auto.opactity-70.navbar-nav {
      opacity: 0.7;
      li a.nav-link:hover {
        // opacity: 0.7;
      }
    }
  }
  // .contact-num-private {
  //   padding-right: 10px;
  // }
  a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }
  a:hover {
    color: #ee7d33;
    text-decoration: none;
  }
  i {
    margin: 0 8px 0 0;
  }
  .private-login-person {
    .welcome-person {
      font-size: 15px;
      color: var(--color-white);
      font-weight: 300;
      display: inline-block;
      margin: 0px;
      p {
        margin: 0px;

        .person-name {
          color: var(--color-white) !important;
          font-weight: 500;
        }
      }
    }
    .private-profile-image {
      display: inline-block;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      margin-right: 10px;
      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
    .private-down {
      margin: 0 15px 0 0;
    }
    .private-message {
      display: inline-block;
      cursor: pointer;
      position: relative;
      .private-message-icon {
        margin: 0px 15px 0 5px;
      }
      .counter {
        font-weight: normal;
        padding: 0;
        line-height: 16px;
        font-size: 11px;
        min-width: 16px;
        /* margin-top: -4px; */
        /* margin-right: 0; */
        /* margin-bottom: 0; */
        /* margin-left: -7px; */
        vertical-align: middle;
        text-align: center;
        border-radius: 50%;
        color: var(--color-white) !important;
        background: var(--color-dark-red);
        position: absolute;
        left: 12px;
        top: -2px;
        opacity: 1;
      }
    }
    .dropdown {
      display: inline-block;
      // margin-left: 10px;
      a {
        padding: 0px;
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .private-dropdown-content {
      a {
        padding: 0px 0px 10px 0px;
        display: block;
        color: var(--text-color-primary);
      }
    }
    .dropdown-menu.dropdown-menu-right.show {
      top: 9px !important;
      width: 205px;
      border-radius: 0px;
    }
    .dropdown-profile {
      padding: 0px 0px 10px 0px;
      border-bottom: 1px solid var(--border-color-primary);
    }
    .dropdown-ads {
      padding: 10px 0px;
      border-bottom: 1px solid var(--border-color-primary);
    }
    .dropdown-message {
      padding: 10px 0px;
      border-bottom: 1px solid var(--border-color-primary);
    }
    .dropdown-signout {
      padding: 10px 0px;
    }
  }

  .dropdown-item {
    &:focus,
    &:hover,
    &:active {
      background: var(--color-white);
    }
  }
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: initial;
  }
}
.mobile-screen {
  display: none;
}

@media (max-width: 991px) {
  .desktop-screen {
    display: none;
  }
  .mobile-screen {
    display: block;
  }
  .private-login-person {
    float: right;
    width: 100%;
    display: block;
    text-align: right;
  }
}
@media (max-width: 767px) {
  .private-login-person {
    width: auto;
  }
}
@media (max-width: 575px) {
  a.custom-width.navbar-brand {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .private-login-person {
    width: 100%;
    text-align: start;
  }
}
