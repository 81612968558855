.top-navigation {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.7);

  .upper-nav {
    a.navbar-brand {
      color: white !important;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      cursor: pointer;
      i{
        margin: 0px 8px 0px 0px;
      }
    }
    a.nav-link {
      color: rgba(255, 255, 255, 0.7) !important;
      font-weight: 500;
      font-size: 14px;
    }
    a.nav-link:hover {
      color: #ee7d33 !important;
    }
    span {
      color: white;
      padding-right: 10px;
      font-size: 14px;
      font-weight: 500;
      opacity: 0.7;
    }
    button {
      color: white;
      padding: 0px 10px 0px 0px;
      font-size: 14px;
      font-weight: 500;
      opacity: 0.7;
    }
    a:hover span {
      color: #ee7d33 !important;
    }
    ul.ml-auto.opactity-70.navbar-nav {
      opacity: 0.7;
    }
  }
  .contact-num {
  i {
    padding-right: 10px;
    color: var(--color-white);
    opacity: 0.7;
  }
  button{
    cursor: pointer;
  }
}
//   .signup a:hover {
//     text-decoration: none;
// }
  .signup a{ 
    &:hover {text-decoration: none;}
    i {
    padding-right: 5px;
    color: var(--color-white);
    opacity: 0.7;
  }
}
  .signin a{
    &:hover{text-decoration: none;}
    i {
    padding-right: 5px;
    color: var(--color-white);
    opacity: 0.7;
  }
}
  .top-navigation {
    color: rgba(255, 255, 255, 0.7);
  }
  .top-navigation a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }
  .top-navigation a:hover {
    color: #ee7d33;
    text-decoration: none;
  }
  .top-navigation i {
    margin: 0 8px 0 0;
  }
}
.top-navigation .dropdown-item {
  &:focus,
  &:active {
    background-color: var(--color-white) !important;
    color: var(--text-color-primary);
    outline: none;
  }
}
.Mobile-Menu {
  display: none;
}
@media only screen and (max-width: 991px) {
  .Desktop-Menu {
    display: none;
  }
  .Mobile-Menu {
    display: block;
  }
  nav.upper-nav.navbar.navbar-expand-md.navbar-light {
    display: inline-block;
    width: 100%;
  }
  a.navbar-brand {
    display: inline-block;
  }
  .aunthentication-main {
    display: inline-block;
    float: right;
  }
  
}

@media(max-width:480px){
  .sign-in-btn{
    background-color: var(--orange);
    color: #fff!important;
    border-radius: 10px;
    padding: 7px 20px;
    text-decoration: none!important;
    width: 50%;
    text-align: center;
  }
  .sign-up-btn{
    background-color: white;
    color: var(--primary)!important;
    border: 1px solid var(--primary);
    border-radius: 10px;
    padding: 7px 20px;
    font-size: 16px;
    margin-right: 10px;
    text-decoration: none!important;
    width: 50%;
    text-align: center;
  }
}