.listing-horizontal {
  .activate-button {
    margin-top: 10px;
    margin-left: 60px;
  }
  .product-boster {
    // margin-top: 100px;
    display: flex;
    flex-direction: column;
    // margin-left: 100px;
    margin-top: 10px;
    // .booster-text{
    //     align-self:center ;
    //     margin-top: -90px;
    //     margin-
    // }
    .booster-button {
      margin-left: 30px;
      margin-top: 65px;
      .btn-custom {
        margin-top: -10px;
      }
    }
  }
  .btn-custom {
    border-radius: 10px;
    background-color: var(--orange);
    color: #fff;
    font-weight: 600;
    padding: 8px 30px;

    &:hover {
      transition: 0.2s linear;
      background-color: var(--primary);
    }
  }
  .card {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    // border: 0;
    // border-radius: 5em/0.4em;
    img {
      width: 100%;
      height: 140px;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    h4 {
      color: var(--orange);
    }
    .img-labels {
      position: relative;
      .discount-horizontal {
        position: absolute;
        background-color: green;
        color: white;
        top: 0px;
        padding: 10px 0;
        text-align: center;
        border-top-left-radius: 10px;
        width: 80px;
        line-height: 20px;
        margin-bottom: 0;
        margin-top: 0px;
      }
    }
    .product-horizontal-label {
      position: absolute;
      top: 00px;
      right: 0px;
      p {
        margin-bottom: 0;
        text-align: center;
        margin-bottom: -10px;
        background-color: var(--orange);
        padding: 5px 10px;
        color: white;
      }
      label {
        font-size: 10px;
      }
    }
  }
  .price-off {
    font-size: 20px;
    margin-bottom: 0;
  }
  .price-discount {
    font-size: 15px;
  }
  .bottom-buttons {
    margin: 23px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-self: center;
    .btn-buy-now {
      border-radius: 0;
      color: white;
      background-color: var(--primary);
      width: 100%;
    }
    .add-cart {
      margin-top: 10px;
      border-radius: 0;
      color: white;
      background-color: var(--orange);
      width: 100%;
    }
  }
  .view-cart {
    background-color: var(--primary);
    color: white;
    border-radius: 0;
    width: 100%;
    margin-top: 22px;
  }
}

@media (max-width: 480px) {
  .listing-horizontal {
    .card {
      .img-labels {
        position: relative;
        .discount-horizontal {
          transform: scale(0.7);
          top: -6px;
          left: 3px;
        }
      }
      .btn-custom {
        font-size: 10px !important;
        white-space: nowrap;
      }
      img {
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      .bottom-buttons {
        margin: 0 !important;
        margin-top: -10px !important;
        display: flex;
        flex-direction: row !important;
        align-self: center;
        .btn-buy-now {
          border-radius: 0;
          color: white;
          background-color: var(--primary);
          width: 100%;
        }
        .add-cart {
          margin-top: 0 !important;
          border-radius: 0;
          color: white;
          background-color: var(--orange);
          width: 100%;
        }
      }
      .deleted-price {
        font-size: 12px !important;
        align-self: center;
      }
      .product-boster {
        margin-top: 0 !important;
        margin-left: 0 !important;
        .activate-button {
          margin-left: 0;
          .btn-custom {
            width: 100%;
            font-size: 10px;
          }
        }
        .booster-button {
          margin-left: 00px;
          margin-top: 0px;
          .btn-custom {
            width: 100% !important;
          }
        }
        .booster-text {
          display: none;
        }
      }
    }
  }
}
