:root {
  --orange: #f68300;
  --lightgrey: #ececec;
  --primary: #023047ed;
  --darkgrey: #707070;
  --bg-color: #fafbfe;
}
#new-featured-ads {
  position: sticky;
  overflow: auto;
  height: 130vh;
  padding: 40px;
  .ad-rights {
    position: -webkit-sticky !important; /* Safari */
    position: sticky !important;
    top: 0 !important;
    height: 150vh;
    .nav-tabs {
      border: 0;
      .nav-item {
        .nav-link {
          // background-color: #f6830032;
          background-color: white;
          border: 1px solid #d1d1d1;
          padding: 20px;
          border-radius: 12px;
          margin: 10px 0;
          cursor: pointer;
          color: #9b9b9b;
          font-weight: 500;
          &:hover {
            background-color: #f6830032;
            color: var(--orange);
            font-weight: 600;
            border: 1px solid transparent;
          }
        }
        .nav-link.active {
          background-color: #f6830032;
          color: var(--orange);
          border: 1px solid transparent;
        }
      }
    }
  }
}

// hiden scroll bar
.example::-webkit-scrollbar {
  display: none;
}

@media (max-width: 480px) {
  #new-featured-ads {
    padding: 30px 0;
  }
}
