.modal.show .post-form-ride {
  transform: translate(0, 110px);
  .modal-content {
    border-radius: 15px;
    border: 0;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 1);
  }
}
.modal-dialog {
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
}
.post-ride-popup-main {
  padding: 50px 30px;
  .post-ride-text {
    width: 100%;
    max-width: 752px;
    margin: 0 auto;
    text-align: center;
    border-bottom: 1px solid var(--border-color-primary);
    h2 {
      font-weight: 700;
      line-height: 45px;
      color: var(--text-color-primary);
      margin-bottom: 0px;
      text-transform: capitalize;
    }
    p {
      color: var(--text-color-primary);
      font-size: 14px;
      font-weight: 500;
      padding: 0 0 20px;
      margin: 0;
    }
  }
  .post-for-car-bike {
    text-align: center;
    padding-top: 50px;
    ul.nav.nav-tabs {
      border: 0;
      display: inline-block;
      li.nav-item {
        display: inline-block;
        padding-right: 100px;
        cursor: pointer;
        &:last-child {
          padding: 0;
        }
        a.nav-link {
          padding: 0;
          border: 0;
          &:hover {
            border: 0;
            .tabs-for-ride {
              .ride-img {
                opacity: 1;
              }
            }
          }
          &:focus {
            border: 0;
          }
          .tabs-for-ride {
            .ride-img {
              width: 100px;
              height: 100px;
              background: var(--color-primary);
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0.5;
            }
            .tab-ride-name {
              margin: 20px 0;
              p {
                margin-bottom: 0;
                line-height: 14px;
                text-transform: capitalize;
                color: var(--text-color-primary);
                font-size: 14px;
                font-weight: 500px;
              }
            }
          }
        }
        a.active.nav-link {
          border: 0;
          opacity: 1;
          .tabs-for-ride {
            .ride-img {
              opacity: 1;
            }
          }
        }
      }
    }
    .tab-content {
      .tab-pane {
        .rides-tabes-content {
          .tabs-content-text {
            margin: 30px 0 50px;
            h2 {
              font-weight: 500;
              line-height: 45px;
              color: var(--text-color-primary);
              text-transform: capitalize;
              margin: 0;
            }
            p {
              font-size: 16px;
              font-weight: 500;
              color: var(--text-color-primary);
              margin: 0;
            }
          }
          .form-for-ride-post {
            .ride-post-form-main {
              text-align: left;
              .postrider-mobile-view {
                display: none;
              }
              h4 {
                line-height: 20px;
                font-weight: 500;
                margin: 0 0 11px;
                color: var(--text-color-primary);
              }
              p {
                font-size: 14px;
                line-height: 20px;
                color: var(--text-color-primary);
              }
              label {
                color: var(--text-color-primary);
                font-size: 16px;
                font-weight: 400;
                margin: 0 0 13px;
              }
              input {
                background: var(--color-background-all) !important;
                border-radius: 0px;
                width: 100%;
                height: 50px;
                &:focus {
                  box-shadow: unset !important;
                  border-color: var(--border-color-primary);
                }
              }
              textarea {
                background: var(--color-background-all) !important;
                border-radius: 0px;
                &:focus {
                  box-shadow: unset !important;
                  border-color: var(--border-color-primary);
                }
              }
              ::placeholder {
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                color: var(--color-text-gray);
              }
              .post-ride-btn {
                display: inline-block;
                margin-top: 35px;
                button {
                  background: var(--color-secondary);
                  font-size: 14px;
                  color: var(--color-white);
                  padding: 19px 114px;
                  font-weight: 900;
                  border-radius: 0;
                  text-transform: uppercase;
                  &:hover {
                    background-color: var(--color-primary);
                  }
                }
              }
              .css-2b097c-container {
                background: var(--color-background-all) !important;
                .css-1wa3eu0-placeholder {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 14px;
                  color: var(--color-text-gray);
                }
                .css-yk16xz-control {
                  height: 50px;
                  background-color: transparent !important;
                  border-radius: 0;

                  .css-1hwfws3 {
                    padding: 0 0.75rem;
                    border: 0;
                    .css-b8ldur-Input {
                      margin: 0 !important;
                      padding: 0 !important;
                      input {
                        height: 48px !important;
                        background: transparent !important;
                      }
                    }
                  }
                  .css-1hb7zxy-IndicatorsContainer {
                    .css-1okebmr-indicatorSeparator {
                      display: none;
                    }
                    .css-tlfecz-indicatorContainer {
                      svg {
                        fill: var(--text-color-primary);
                      }
                    }
                  }
                }
                .css-1pahdxg-control {
                  height: 50px;
                  background-color: transparent !important;
                  border-radius: 0;
                  box-shadow: unset !important;
                  border-color: var(--border-color-primary) !important;
                  &:hover {
                    border-color: var(--border-color-primary) !important;
                  }
                  .css-1hwfws3 {
                    padding: 0 0.75rem;
                    border: 0;
                    .css-b8ldur-Input {
                      margin: 0 !important;
                      padding: 0 !important;
                      input {
                        height: 48px !important;
                        background: transparent !important;
                      }
                    }
                  }
                  .css-1hb7zxy-IndicatorsContainer {
                    .css-1okebmr-indicatorSeparator {
                      display: none;
                    }
                    .css-tlfecz-indicatorContainer {
                      svg {
                        fill: var(--text-color-primary);
                      }
                      .css-1gtu0rj-indicatorContainer {
                        svg {
                          fill: var(--text-color-primary);
                        }
                      }
                    }
                  }
                }
              }
              .upload-img-section {
                .upload-image {
                  .upload-image-main {
                    p {
                      margin: 0 0 11px;
                      font-size: 16px;
                      font-weight: 400;
                      color: var(--text-color-primary);
                    }
                    .images {
                      margin: 0 0 20px;
                      img {
                        margin: 0 12px 12px 0;
                        width: 90px;
                        height: 90px;
                        &:last-child {
                          margin: 0 0 12px 0;
                        }
                      }
                    }
                    .image-btn {
                      position: relative;
                      img {
                        margin-right: 5px;
                      }
                      button {
                        background: var(--color-secondary);
                        color: var(--color-white);
                        font-size: 12px;
                        font-weight: 500;
                        border-radius: 0;
                        padding: 8px 14px;
                      }
                    }
                    .image-note {
                      font-size: 12px;
                      line-height: 21px;
                      color: var(--text-color-primary);
                    }
                  }
                }
              }
              .btn-common {
                background-color: var(--color-white);
                border: 1px solid var(--border-color-primary);
                padding: 7px 12px;
                float: right;
                margin: 20px 15px;

                label {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 26px;
                  color: var(--text-color-primary);
                  margin-right: 59px;
                  float: left;
                  margin-bottom: 0;
                }

                .custom-control {
                  float: right;
                  width: 64px;
                  padding: 0 0 0 40px;

                  label {
                    &::before {
                      width: 60px;
                      pointer-events: all;
                      border-radius: 1.5rem;
                      height: 26px;
                      top: 0px;
                      background-color: var(--color-light-dark);
                      border-color: var(--color-light-dark);
                    }

                    &::after {
                      top: calc(-2px + 2px);
                      left: calc(-37px + 2px);
                      width: calc(30px - 4px);
                      height: calc(30px - 4px);
                      background-color: var(--color-white);
                      border-radius: 50%;
                    }
                  }

                  .custom-control-input:checked ~ .custom-control-label::before {
                    background-color: var(--color-primary);
                    border-color: var(--color-primary);
                  }
                }

                .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
                  transform: translateX(2rem) !important;
                }
              }
            }
          }
          .savebtn button {
            background: var(--color-secondary);
            font-size: 16px;
            color: var(--color-white);
            margin: 15px 0px 30px 0px;
            padding: 19px 75px;
            font-weight: 900;
            border-radius: 0px;
            float: right;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .postrider-desktop-view {
    display: none;
  }
  .postrider-mobile-view {
    display: block !important;
  }
  .post-ride-popup-main .post-for-car-bike .tab-content .tab-pane .rides-tabes-content .savebtn button {
    padding: 15px 55px;
  }
}
@media (max-width: 575px) {
  .post-ride-popup-main .post-for-car-bike ul.nav.nav-tabs li.nav-item {
    padding-right: 30px;
  }
}
