:root {
  --color-primary: #083c6a;
  --color-secondary: #ee7d33;
  --color-background-all: #f8f8f8;
  --color-background-light-gray: #e7e7e7;
  --color-white: #fff;
  --color-text-gray: #777;
  --color-grey-disabled: #c3c3c3;
  --color-light-dark: #808080;
  --text-color-primary: #3e3e3e;
  --bg-color-opacity: #00000041;
  --bg-color-overlay: #000000d5;
  --border-color-primary: #dbdbdb;
  --color-light-gray: #fbfbfb;
  --color-dark-red: #b02432;
  --color-bright-red: #f65656;
  --color-success: #45b549;
  --color-chat-light-blue: #edf6ff;
  --color-chat-light-green: #d5ffe1;
  --font-family-primary: "Roboto", sans-serif;
  // --------------- New Color -----------------
  --orange: #f68300;
  --lightgrey: #ececec;
  --primary: #023047ed;
  --darkgrey: #707070;
  --bg-color: #fafbfe;
  // ------------------ Ends -------------------
}
#checkbox {
  input[type="checkbox"] {
    /* remove browser chrome */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    /*add styling */
    padding: 10px 10px !important;
    position: relative;
    width: 1rem !important;
    height: 1rem;
    border: 1px solid grey;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transform: scale(0.9);
  }
  input[type="checkbox"]::before {
    content: "";
    color: #fff;
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    background-color: transparent;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 2px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    /* base64 encoded to make things easier to show 
              normally you would use an image or a font
          */
    background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjQ0OCIgaGVpZ2h0PSI0NDgiIHZpZXdCb3g9IjAgMCA0NDggNDQ4Ij4KPHRpdGxlPjwvdGl0bGU+CjxnIGlkPSJpY29tb29uLWlnbm9yZSI+CjwvZz4KPHBhdGggZD0iTTQxNy43NSAxNDEuNWMwIDYuMjUtMi41IDEyLjUtNyAxN2wtMjE1IDIxNWMtNC41IDQuNS0xMC43NSA3LTE3IDdzLTEyLjUtMi41LTE3LTdsLTEyNC41LTEyNC41Yy00LjUtNC41LTctMTAuNzUtNy0xN3MyLjUtMTIuNSA3LTE3bDM0LTM0YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsNzMuNSA3My43NSAxNjQtMTY0LjI1YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsMzQgMzRjNC41IDQuNSA3IDEwLjc1IDcgMTd6Ij48L3BhdGg+Cjwvc3ZnPgo=");
  }

  input[type="checkbox"]:checked::before {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

// iframe{
//   position:unset !important;
//   top: 0px;
//   left: 0px;
//   width: 100%;
//   height: 0%!important;
//   border: none;
//   z-index: 0 !important;
// }

@media (max-width: 480px) {
  // .postyouradd-carinfo-custom {
  .modal-dialog {
    height: 100vh !important;
    align-self: center;
    .modal-content {
      transform: translateY(25%);
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      .modal-body {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
  // }
}
.input{
  width: 100%;
}
