.left-menu-bottom-main {
  .left-bottom-status {
    margin-top: 5px;
    a {
      background: var(--color-secondary);
      display: block;
      padding: 11px 20px 12px 20px;
      span {
        font-size: 18px;

        line-height: 14px;
        color: var(--color-white);
      }
      &:hover {
        background: var(--color-primary);
        text-decoration: none;
      }
    }

    .btn.auctionsheet-btn {
      background: var(--color-secondary);
      display: block;
      padding: 11px 20px 12px 20px;
      width: 100%;
      border-radius: 0px;
      span {
        font-size: 18px;
        float: left;
        line-height: 23px;
        color: var(--color-white);
      }
      &:hover {
        background: var(--color-primary);
        text-decoration: none;
      }
    }
  }
}
.auctionsheet-modalpopup {
  width: 60% !important;
}
@media only screen and (max-width: 991px) {
  .left-menu-bottom-main .left-bottom-status a span {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .left-menu-bottom-main .left-bottom-status a span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 667px) {
  .auctionsheet-modalpopup {
    width: 100% !important;
  }
}
