.advance-search {
  margin: 0;
  .col-md-4 {
    margin-top: 12px;
    label {
      display: block;
      text-align: left;
      font-weight: 600;
    }
  }
  .min-max {
    margin-top: 12px;

    span.css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-2b097c-container {
      width: 50%;
      display: inline-block;
    }
    .css-2b097c-container:first-child {
      margin-right: 8%;
    }
  }
  & .css-yk16xz-control,
  & .css-1pahdxg-control {
    padding: 8px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid var(--border-color-primary) !important;
    .css-1hwfws3 {
      padding: 0;
    }

    .css-1wa3eu0-placeholder {
      color: #3e3e3e !important;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-6q0nyr-Svg {
      color: #3e3e3e !important;
    }
  }
  .css-26l3qy-menu {
    margin: 0 !important;
    border-radius: 0px !important;
    text-align: left !important;

    & > div {
      padding: 0 !important;
    }
  }
}
