.App {
  text-align: center;
}

.jazzcash.col-md-4.col-sm-4 {
  display: none !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image-gallery-content.left {
  display: flex;
}

input.whatsapp-switcher[type="checkbox"] {
  position: relative;
  width: 50px;
  height: 25px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  box-shadow: inset 0 0 5px rgba(255, 0, 0, 0.2);
  transition: 0.7s;
  cursor: pointer;
}

input.whatsapp-switcher:checked[type="checkbox"] {
  background: #28a745;
}

input.whatsapp-switcher[type="checkbox"]:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  top: 0;
  left: 0;
  background: #ffffff;
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: .5s;
}

input.whatsapp-switcher:checked[type="checkbox"]:before {
  left: 25px;
}

.whatapp-switcher-wrapper {
  padding: 10px 0px 20px;
  display: flex;
  align-items: center;

  span {
    line-height: normal;
    margin-bottom: -1px;

    .text-success {
      position: absolute;
      right: 15px;
      /* width: 57px; */
      top: 3px;
      font-size: 36px;
    }
  }

}


// My css

// variables
$primary: #143f54;
$secondary: #f68300;
$transparent: rgba(0, 0, 0, 0.0);
$darkblue: #0c3148;
$orange: #f68300;
$lightgrey: #f5f5f5;
$lightgrey2: #fafbfe;
$lightgrey3: #f2f2f2;
$white: #fff;
$twitter: #1DA1F2;
$facebook: #4267B2;
$whatsapp: #25d366;
$google: #DB4437;
$bs-border-color: #ced4da;


// Utils
.text-primary{
  color: $primary !important;
}
.text-secondary{
  color: $secondary !important;
}
.text-lightgrey{
  color: $lightgrey !important;
}
.text-lightgrey2{
  color: $lightgrey2 !important;
}
.text-lightgrey3{
  color: $lightgrey3 !important;
}
.text-twitter{
  color: $twitter !important;
}
.text-facebook{
  color: $facebook !important;
}
.text-whatsapp{
  color: $whatsapp !important;
}
.text-google{
  color: $google !important;
}
.text-darkblue{
  color: $darkblue !important;
}

.bg-primary{
  background-color: $primary !important;
}
.bg-secondary{
  background-color: $secondary !important;
}
.bg-lightgrey{
  background-color: $lightgrey !important;
}
.bg-lightgrey2{
  background-color: $lightgrey2 !important;
}
.bg-lightgrey3{
  background-color: $lightgrey3 !important;
}
.bg-twitter{
  background-color: $twitter !important;
}
.bg-facebook{
  background-color: $facebook !important;
}
.bg-whatsapp{
  background-color: $whatsapp !important;
}
.bg-google{
  background-color: $google !important;
}
.bg-darkblue{
  background-color: $darkblue !important;
}

.text-small {
    font-size: 12px;
}
.text-large {
    font-size: 20px;
}
.bullet-pointes{
  list-style-type: none;
}
.section-padding{
  padding: 50px 0;
}
.max-w-md{
  max-width: 767px;
}
.max-w-sm{
  max-width: 575px;
}
.rounded-2{
  border-radius: 8px;
}
.rounded-3{
  border-radius: 16px;
}
.gap-x-3{
  gap: 0px 16px;
}
.gap-y-3{
  gap: 16px 0;
}
.gap-3{
  gap: 16px;
}
.no-decoration{
  text-decoration: none;
  &:hover,
  &:focus{
    text-decoration: none;
  }
}
.cursor-pointer{
  cursor: pointer;
}
.w-100-on-mobile{
  @media(max-width:480px){
    width: 100%;
    max-width: 100%;
  }
}
.no-list-style{
  list-style-type: none;
}
.hover-translate-x-2{
  transform: translateX(0px);
  transition: .2s;
  display: inline-block;
  &:hover{
    transform: translateX(8px);
  }
}
.stylish-list{
  li{
    padding-left: 24px;
    position: relative;
    list-style-type: none;
    &:before{
      position: absolute;
      font-size: 15px;
      left: 0;
      top: 0;
      color: inherit;
      font-family: "Font Awesome 5 Free"; font-weight: 400; content: "\f0a4";
    }
  }
}
.table-box{
  overflow: auto;
  table{
    width: calc(100% - 2px);
  }
}
.scroll-from-small-tab{
  table{
    @media(max-width:575px){
      width: 576px;
    }
  }
}

/***********************
 Bootstrap Overrides
***********************/
.form-control{
  border-radius: 4px !important;
  height: 46px !important;
  &:focus{
    border-color: $secondary !important;
    box-shadow: none;
  }
  &.form-control-stylish,
  &.form-control-stylish:focus{
    border: 0 !important;
    border-bottom: 3px solid $secondary !important;
    background-color: #fffaf5;
    border-radius: 0;
  }
}

/***********************
 COMPONENTS
***********************/

// Button
.adp-btn {
    border: 1px solid $transparent;
    padding: 10px 16px;
    transition: .2s;
    display: inline-flex;
    align-items: center;
    justify-content: center;  
    position: relative;
    overflow: hidden;
    text-align: center;
    min-width: 120px;
    text-decoration: none;
    border-radius: 4px;
    &:hover,
    &:focus{
        text-decoration: none;
        text-decoration: none;
    }
}

.adp-btn-primary{
    background-color: $primary;
    color: $white;
    border: 1px solid $primary;
}
.adp-btn-secondary{
    background-color: $secondary;
    color: $white;
    border: 1px solid $secondary;
}
.adp-btn-primary,
.adp-btn-secondary{
    &:after {
        background: $white;
        content: "";
        height: 155px;
        left: -75px;
        opacity: .2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
    }
    &:hover{
        color: $white;
        &:after{
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }
}

.adp-btn-primary-outline{
  border: 1px solid $primary;
  background-color: $transparent;
  color: $primary;
  &:hover{
    background-color: $primary;
    color: $white;
  }
}
.adp-btn-secondary-outline{
  border: 1px solid $secondary;
  background-color: $transparent;
  color: $secondary;
  &:hover{
    background-color: $secondary;
    color: $white;
  }
}
.adp-btn-white{
  border: 1px solid $white;
  background-color: $white;
  color: $secondary;
  &:hover{
    background-color: $secondary;
    color: $white;
  }
}
.adp-btn-lightgrey{
  border: 1px solid $lightgrey;
  background-color: $lightgrey;
  color: $primary;
  &:hover{
    background-color: $primary;
    color: $white;
  }
}

// Small checkbox
.checkbox-small{
  #checkbox {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    input[type="checkbox"] {
      width: 10px !important;
      height: 10px !important;
      padding: 7px !important;
    }
    span{
      font-size: 14px;
      margin-top: -2px;
      width: calc(100% - 16px);
      padding-left: 4px;
    }
  }
}

// Left Col Fixed
.left-fixed-two-col{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  > div{
    margin-bottom: 8px;
  }
  .left-col{
    width: 70px;
  }
  .right-col{
    width: calc(100% - 70px);
    padding-left: 16px;
    font-size: 14px;
  }
}


// Button Container
.btns-container{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .adp-btn{
    flex: 1;
  }
}

// Box Radio Buttons
.box-radio-container{
  display: flex;
  flex-wrap: wrap;

   .form-label{
    width: 100%;
    margin-bottom: 8px;
   }

  .box-radio {
    width: 50px;
    height: 40px;
    position: relative;
  }
  .box-radio label,
  .box-radio input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .box-radio input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
  }
  .box-radio input[type="radio"]:checked + label {
    border-radius: 4px;
    border: 1px solid $orange;
    background-color: $orange;
    color: $white;
  }
  .box-radio label {
    cursor: pointer;
    // z-index: 90;
    margin-bottom: 0;
    border: 1px solid $bs-border-color;
    border-radius: 0 !important;
    line-height: 1.6;
  }

  &.three-btns{
    .box-radio{
      width: 33.33%;
    }
  }
  &.five-btns{
    .box-radio{
      width: 20%;
    }
  }
}

// Finance Calc Form Box
.finance-calc-form-box{
  .finance-calc-bordered-area{
    border: 1px solid $bs-border-color;
    padding: 16px 8px;
    border-radius: 0 4px 4px 4px;
    background-color: $white;
  }
  .top-badge {
    font-size: 12px;
    padding: 5px 16px 4px;
    background: $orange;
    width: fit-content;
    border-radius: 4px 4px 0 0;
    color: $white;
  }
}

// Vertical row container
.vertical-row-container{
    border-radius: 4px;
    .single-row{
        display: flex;
        flex-wrap: wrap;
        // margin-bottom: 16px;
        border: 1px solid #dee2e6;
        border-bottom: 0;
        &:nth-child(even){
          background-color: #dee2e6;
          .inner1{
            border-right: 1px solid #f5f5f5;
          }
        }
        &:last-child{
          border-bottom: 1px solid #dee2e6;
        }
        > div{
            padding: 8px;
        }
        .inner1{
            width: 80px;
            min-height: 80px;
            border-right: 1px solid #dee2e6;
            // background-color: $primary;
            // border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            // box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, .2);
            .row-icon{
              img{
                max-width: 40px;
                max-height: 40px;
                // filter: invert(1);
              }
            }
        }
        .inner2{
            width: calc(100% - 96px);
            font-size: 14px;
        }
    }
}

//stylish-tabs
.ad-tabs-nav-box {
  .nav-tabs {
    border-bottom: 0;
    padding-left: 3px;
    padding-right: 3px;
    position: relative;
    &::before {
    content: "";
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: -5px;
      left: 1px;
      background: #f68300;
      clip-path: polygon(0 4px, calc(100% - 4px) 0, 100% 100%, 100% 100%);
      // @media(max-width:991px){
      //   display: none;
      // }
    }

    .nav-item {
      min-width: 150px;
      text-align: center;
      padding: 0px 19px;
      margin-left: -10px;
      @media(max-width:991px){
        margin: 0;
        padding: 0;
        min-width: unset;
      }
      &:first-child{
        margin-left: 0;
      }
    }
    .nav-link {
      padding: 10px 34px;
      text-transform: uppercase;
      position: relative;
      background-color: #ccc;
      border: 0;
      color: var(--primary);
      @media(max-width:991px){
        padding: 10px 16px;
        font-size: 14px;
        background-color: transparent;
      }
      @media(max-width:480px){
        font-size: 12px;
      }
      @media(max-width:380px){
        font-size: 10px;
      }
      &::before,
      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 32px;
        background-color: #ccc;
        color: #143f54;
        border-radius: 4px;
        @media(max-width:991px){
          display: none;
        }
      }
      &::before{
        left: -10px;
        transform: skew(-22deg, 0deg);
        box-shadow: rgba(0, 0, 0, .1) -3px 0px 5px, inset rgba(255, 255, 255, .9) 0px 0;
      }
      &::after{
        right: -10px;
        transform: skew(22deg, 0deg);
        box-shadow: rgba(0, 0, 0, .1) 3px 0px 5px, inset rgba(255, 255, 255, .9) -0px 0;
      }
    }
    .nav-link.active {
      background-color: var(--orange);
      color: white;
      z-index: 1;
      &::before,
      &::after{
        background-color: var(--orange);
        border-radius: 4px;
      }
    }
  }
  
}
.ad-tabs-content-box{
  .tab-content{
    padding-top: 24px;
  }
}

// Sticky tabs
.sticky-tabs-nav{
  display: flex;
  gap: 4px;
  padding: 6px 0 0;
  border: 1px solid $bs-border-color;
  position: sticky;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 2;
  width: 100%;
  overflow-x: auto;
  // @media(max-width:991px){
  //   margin-left: -15px;
  //   margin-right: -15px;
  //   width: calc(100% + 30px);
  // }
  > a{ 
    font-size: 14px;
    min-width: unset;
    padding: 4px 10px;
    color: $primary;
    border-bottom: 4px solid $transparent;
    white-space: nowrap;
    // @media(max-width:991px){
    //   padding: 4px 16px;
    // }
    &.active{
      border-bottom: 4px solid $primary;
    }
  }
}
.sticky-tabs-nav-dropdown{
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}

.ad-horizontal-tabs{
  display: flex;
  flex-wrap: wrap;
  .nav{
    border-right: 4px solid $lightgrey;
    border-left: 4px solid $lightgrey;
    background-color: $white;
    @media(max-width: 575px){
      border: 0;
    }
  }
  .nav-item{
    margin-right: -4px;
    margin-left: -4px;
    width: calc(100% + 8px);
    @media(max-width: 575px){
      width: fit-content;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .nav-link{
    background-color: $transparent;
    color: $primary;
    border-bottom: 1px solid $lightgrey;
    border-right: 4px solid $transparent;
    padding: .75rem 1rem;
    @media(max-width: 575px){
      border-bottom: 2px solid $lightgrey2;
      border-right: 0;
      padding: .5rem .75rem;
    }

    &.active{
      background-color: $lightgrey;
      color: $secondary;
      border-right: 4px solid $primary;
      border-bottom: 0;
      border-radius: 4px;
      @media(max-width: 575px){
        border-bottom: 2px solid $primary;
        border-right: 0;
        border-radius: 4px 4px 0 0;
      }
    }

    
  }
  .left-col{
    width: 300px;
    @media(max-width: 991px){
      width: 250px;
    }
    @media(max-width: 575px){
      width: 100%;
      margin-bottom: 24px;
    }
  }
  .right-col{
    width: calc(100% - 300px);
    padding-left: 16px;
    @media(max-width: 991px){
      width: calc(100% - 250px);
    }
    @media(max-width: 575px){
      width: 100%;
      padding-left: 0;
    }
  }
}

// cards
.ad-card{
  border: 1px solid #dee2e6;
  background-color: $white;
  border-radius: 4px;
  overflow: hidden;
  transition: .3s;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  transform: translateY(0px);
  .card-banner {
    height: 180px;
    border-bottom: 1px solid #dee2e6;
    position: relative;
    .card-banner-badges{
      background-color: #838383;
      position: absolute;
      top: 0px;
      left: 0px;
      display: flex;
      padding: 2px 8px;
      border-radius: 4px 0 4px 0;
      color: white;
    }

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .payments {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    .inner1,
    .inner2{
      max-width: 50%;
    }
  }
  .card-footer{
    background-color: $transparent;
  }

  &:hover{
    box-shadow: 0px 3px 8px 0px #143f542e;
    transform: translateY(-2px);
  }
}

.count-card{
  border-radius: 4px;
  max-width: 400px;
  font-size: 14px;
  height: 100%;
  .count-card-header{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 2px solid $primary;
    margin-bottom: 16px;
    .count{
      width: 50px;
      height: 50px;
      background-color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: $white;
      font-weight: bold;
    }
    .count-card-title{
      width: calc(100% - 50px);
      margin-bottom: 0;
      padding-left: 16px;
      color: $primary;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.news-card{
  overflow: hidden;
  .news-card-banner{
    height: 180px;
    overflow: hidden;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .3s;
      transform: scale(1);
    }
    &:before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .5);
      opacity: 1;
      z-index: 1;
      transition: .3s;
    }

    
  }
  .news-card-body{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    height: calc(100% - 180px);
    > *{
      width: 100%;
    }
  }
  .news-card-body-bottom{
    a{
      transition: .3s;
      display: inline-block;
      transform: translateX(0px);
    }
  }
  

  &:hover{
    .news-card-banner{
      img{
        transform: scale(1.25);
      }
      &:before{
        opacity: 0;
      }
    }

    .news-card-body-bottom{
      a{
        transform: translateX(8px);
      }
    }
  }
}


.regulation-card {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #143f5429;
    
    &:last-child{
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
    .card-icon-box{
      width: 80px;
      display: flex;
      justify-content: center;
      img{
        max-width: 50px;
        max-height: 50px;
        // filter: invert(1);
      }
    }
    .card-description-box {
      width: calc(100% - 80px);
      padding-left: 16px;
      h4{
        color: $secondary;
      }
      .card-description{
        font-size: 14px;
        p{
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
}

.why-us-card{
  border: 1px solid #dee2e6;
  background-color: $white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  .why-us-card-header{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 16px;
    svg,
    img{
      width: 50px;
    }
    h4{
      width: calc(100% - 50px);
      padding-left: 16px;
      margin-bottom: 0;
      color: $primary;
    }
  }
  .why-us-card-body{
    p{
      font-size: 14px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

/***********************************
  MAIN CSS
***********************************/
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
  height: 38px;
  width: 25px;
}
.image-gallery-thumbnail{
  border-radius: 8px;
  overflow: hidden;
}
.image-gallery-thumbnails {
    padding: 5px 0 0;
}
.image-gallery-thumbnail img{
  border-radius: 4px;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{
  border-color: $secondary;

}

.image-slider-main{
  .image-gallery-slides{
    background: #000;
  }
  .image-gallery-image{
    object-fit: cover;
  }
  .image-gallery-thumbnails-wrapper{
    @media(max-width: 575px){
      display: none;
    }
  }
}

.share-icons-container{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  position: absolute;
  bottom: 208px;
  right: 24px;
  @media(max-width:575px){
    bottom: 85px;
    right: 0px;
  }
  @media(max-width:480px){
    bottom: 85px;
    right: 0px;
  }
  button{
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $white;
    font-size: 13px;
  }
}
.product-extra-info{
  .adp-btn{
    @media(max-width: 575px){
      font-size: 14px;
    }
  }
}
.buy-finance-entirely{
  border-top: 4px solid $secondary;
  @media(max-width:480px){
    margin-left: -15px;
    margin-right: -15px;
  }
}


/* STYLES THAT HAVE TO REMOVE */
/*PopularCars*/
#popular-new-cars {
  .btn-custom {
    border-radius: 10px;
    background-color: var(--orange);
    color: white;
    font-weight: 500;
    padding: 5px 30px;
    &:hover {
      background-color: var(--primary);
      transition: 0.3s linear;
    }
  }
  h3 {
    font-size: 30px;
    font-weight: 500;
  }
  .popular-listing {
    margin-top: 30px;
    .main-popular {
      margin-bottom: 20px;
      .popular-cars-img {
        display: flex;
        justify-content: center;
        img {
          width: auto;
          height: 150px;
          object-fit: contain;
        }
      }
      .popular-cars-details {
        padding: 0 20px;
        text-align: center;
        h5 {
          color: var(--orange);
          font-size: 24px;
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .popular-cars-price {
          font-size: 14px;
          font-weight: 400;
          color: #7c7b7b;
        }
      }
    }
  }
}

.popular-listing-slider{
  max-width: 70%;
  width: 100%;
  .slick-list{
    overflow: visible;
    .slick-slide{
      height: auto;
    }
  }
  .popular-listing-slider-slide{
    .popular-cars-img {
        display: flex;
        justify-content: center;
        img {
          width: auto;
          height: 150px;
          object-fit: contain;
        }
      }
      .popular-cars-details {
        padding: 0 20px;
        text-align: center;
        h5 {
          color: var(--orange);
          font-size: 24px;
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .popular-cars-price {
          font-size: 14px;
          font-weight: 400;
          color: #7c7b7b;
        }
      }
  }
}
.custom-duty-clearance-slider{
  max-width: 80%;
  width: 100%;
  .slick-list{
    overflow: visible;
    .slick-slide{
      height: auto;
    }
  }
}

// ============================ Recent Cars ================================
#recent-new-cars {
  margin-top: 50px;
  .btn-custom {
    border-radius: 10px;
    background-color: var(--orange);
    color: white;
    font-weight: 500;
    padding: 5px 30px;
    &:hover {
      background-color: var(--primary);
      transition: 0.3s linear;
    }
  }
  h3 {
    font-size: 30px;
    font-weight: 500;
  }
  .recent-listing {
    margin-top: 30px;
    .main-recent {
      margin-bottom: 20px;
      .recent-cars-img {
        display: flex;
        justify-content: center;
        img {
          width: auto;
          height: 150px;
          object-fit: contain;
        }
      }
      .recent-cars-details {
        padding: 0 20px;
        text-align: center;
        h5 {
          color: var(--orange);
          font-size: 24px;
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .recent-cars-price {
          font-size: 14px;
          font-weight: 400;
          color: #7c7b7b;
        }
        .esitimate-recent {
          margin-top: 30px;
          .est-col {
            font-size: 14px;
            border: 1px solid #aaa8a8;
            color: #aaa8a8;
            padding: 8px;
            &:hover {
              color: var(--orange);
            }
          }
        }
      }
    }
  }
}

// ============================ Popular Cars ================================
#upcoming-new-cars {
  margin-top: 50px;
  .btn-custom {
    border-radius: 10px;
    background-color: var(--orange);
    color: white;
    font-weight: 500;
    padding: 5px 40px;
    &:hover {
      background-color: var(--primary);
      transition: 0.3s linear;
    }
  }
  h3 {
    font-size: 30px;
    font-weight: 500;
  }
  .upcoming-listing {
    margin-top: 30px;
    .main-upcoming {
      margin-bottom: 20px;
      .upcoming-cars-img {
        display: flex;
        justify-content: center;
        img {
          width: auto;
          height: 150px;
          object-fit: contain;
        }
      }
      .upcoming-cars-details {
        padding: 0 20px;
        text-align: center;
        h5 {
          color: var(--orange);
          font-size: 24px;
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .upcoming-cars-price {
          font-size: 14px;
          font-weight: 400;
          color: #7c7b7b;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .only-popular-cars {
    margin-top: 10px !important;
  }
  #popular-new-cars {
    .popular-listing {
      .main-popular {
        .popular-cars-img {
          img {
            height: 120px !important;
          }
        }
      }
    }
  }
}
/*PopularCarsEnd*/

/*ReviewsCard*/
#recent-car-review {
  .recent-reviews {
    .reviews-card {
      margin-bottom: 30px;
      padding: 20px;
      border-radius: 10px;
      border: 0;
      transition: .3s;
      cursor: pointer;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      &:hover {
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
        transform: translateY(-0.9%);
        transition: 0.3s linear;
      }
      .recent-car-review-img {
        img {
          width: 100px;
        }
      }
      .review-title {
        align-self: center;
        h5 {
          font-size: 20px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #000;
        }
      }
      .user-review {
        margin-top: 10px;
        h5 {
          color: #000;
          font-size: 20px;
        }
        p {
          color: #000;
        }
      }
    }
  }
}

.reviews-card {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
  transition: .3s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-0.9%);
    transition: 0.3s linear;
  }
  .recent-car-review-img {
    img {
      width: 70px;
    }
  }
  .review-title {
    align-self: center;
    h5 {
      font-size: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #000;
    }
  }
  .user-review {
    margin-top: 10px;
    h5 {
      color: #000;
      font-size: 20px;
    }
    p {
      color: #000;
    }
  }
}
/*ReviewsCardEnd*/


/*Circle Arrows*/
.circle-arrows-slider{
  .slick-arrow {
    z-index: 999;
    &:before{
      position: absolute;
      left: 0px;
      bottom: 0;
      width: 100%;
      height: 100%;
      font-weight: 900;
      opacity: 1;
      border-radius: 50%;
      font-size: 45px;
      color: rgba(0, 0, 0, 0.2);
      transition: .3s;
    }

    &:hover{
      &:before{
        color: rgba(0, 0, 0,0.5);
      }
    }
  }
  
  button.slick-arrow.slick-prev {
    left: 0px !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    opacity: 1;
  }
  button.slick-arrow.slick-next {
    right: 0px !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    opacity: 1;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 1;
  } 

  .slick-next:before {
    content: "→";
  }
  .slick-prev:before {
    content: "←";
  }
}
/*Circle Arrows End*/


.splide__list{
  height: auto !important;
}

/*Horizontal form box*/
.horizontal-form-box{
  background-color: $white;
  max-width: 720;
  width: 100%;
  .nav-tabs.outer-nav{
    li{
      flex: 1;
      a{
        color: $white;
        width: 100%;
        background-color: $primary;
        border-radius: 0;
        border: 1px solid $white;
        border-bottom: 0;
        text-align: center;
        padding: 12px 16px;
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        @media(max-width:767px){
          font-size: 12px;
        }
        &:before{
          content: "";
          width: 20px;
          height: 20px;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid transparent;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &.active{
          background-color: $secondary;
          border-color: $white;
          color: $white;
          &:before{
            border-bottom: 10px solid $white;
          }
        }
      }
    }
  }
  .tab-content.outer-content{
    min-height: 130px;
    padding: 16px;
    border: 1px solid #dee2e6;
    border-top: 0;
  }


  .inner-nav.nav-tabs{
    a{
      border-bottom: 2px solid $transparent;
      color: $primary;
      &.active{
        border-bottom: 2px solid $secondary;
      }
    }
  }
  .tab-content.inner-content{
    padding-top: 16px;
    .adp-btn {
      padding: 6px 12px;
    }
  }
}
/*Horizontal form box end*/
.adp-btn-secondary{
  .checked-icon {
    background-color: #3eb549;
    border-radius: 50%;
    bottom: 20px;
    color: #fff;
    font-size: 10px;
    height: 18px;
    line-height: 18px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;
    right: 5px;
  }
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.inquiry-form-box{
  .form-group{
    margin-bottom: 1.8rem;
  }
  input.form-control {
    height: 45px;
  }
}
.search-form-box{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 767px;
  padding: 32px 16px;
  border: 1px solid #dee2e6;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  .inner1{
    flex: auto;
  }
}
.videos-slider-slider{
  .slick-slide{
    height: auto;
  }
}


// comparison page
.comparison-banner{
  padding: 70px 0 100px;
}
.comparison-form {
    max-width: 900px;
    border: 1px solid #dee2e6;
    padding: 32px 16px;
    margin-top: -140px;
    background: #fff;
    border-radius: 4px;
}
.choose-your-own-card{
  padding: 24px;
  border: 1px solid #dee2e6;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0);
  transition: .3s;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);

  .add-icon{
    margin-bottom: 24px;
    img{
      width: 50px;
    }
  }

  &:hover{
    transform: translateY(-2px);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
}
.comparison-box{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  border: 1px solid #dee2e6;
  cursor: pointer;
  transform: translateY(0);
  transition: .3s;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  &:before{
    content: "VS";
    width: 50px;
    height: 50px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: $secondary;
  }
  .comparison-box-col{
    width: 50%;
    &.comparison-box-col1{
      border-right: 1px solid #dee2e6;
      padding: 16px 30px 16px 16px;
    }
    &.comparison-box-col2{
      padding: 16px 16px 16px 30px;
    }
    .vehicle-img-box{
      img{
        max-width: 100%;
      }
    }
  }

  &:hover{
    transform: translateY(-2px);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
}

// comparison page end
.dropdown-toggle.arrow-right{
  &:before{
    width: fit-content;
    font-size: 18px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

/*Reviews page*/
.write-review-box{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 24px;
  background: #fff;
  max-width: 767px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  .icon-box{
    width: 50px;
    text-align: center;
    img{
      max-width: 50px;
    }
  }
  .text-box{
    width: calc(100% - 200px);
    padding: 8px 16px 8px 32px;
  }
  .btn-box{
    width: 150px;
    text-align: right;
  }

  @media(max-width: 767px){
    .icon-box,
    .text-box,
    .btn-box{
      width: 100%;
      // margin-bottom: 16px;
    }
    .text-box{
      padding: 16px;
      input{
        text-align: center;
      }
    }
    .btn-box{
      // margin-bottom: 0;
      text-align: center;
    }
  }
}
/*Reviews page end*/
.fixed-popup-compare{
  position: fixed;
  bottom: 15px;
  left: 15px;
  right: 15px;
  background: white;
  z-index: 1;
  padding: 25px;
  box-shadow: 1px 0px 10px #ddd
}