#ad-card {
  border-radius: 0;
  background-color: #f2f4f4;
  padding: 25px;
  .main-img {
    position: relative;
    img {
      width: 100%;
      height: 370px;
      object-fit: cover;
    }
    .active-btn {
      position: absolute;
      border-radius: 8px;
      padding: 5px 20px;
      background-color: #56bb51;
      color: white;
      font-weight: 500;
      left: 10px;
      top: 10px;
    }
    .edit-btn {
      position: absolute;
      border-radius: 8px;
      padding: 5px 20px;
      background-color: var(--orange);
      color: white;
      font-weight: 500;
      right: 10px;
      top: 10px;
    }
    .img-count {
      position: absolute;
      border-radius: 4px;
      padding: 4px 10px;
      background-color: rgba(0, 0, 0, 0.2);
      color: white;
      font-weight: 500;
      bottom: 10px;
      left: 10px;
    }
    .product-horizontal-label {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      color: white;
      font-weight: 500;
      bottom: 10px;
      left: 80px;
      padding: 4px 10px;
      border-radius: 4px;
    }
    .certified-icon {
      svg {
        position: absolute;
        right: 10px;
        bottom: 0px;
        width: 80px;
        height: 60px;
      }
    }
  }
  .ad-car-details {
    h2 {
      font-weight: 500;
      color: var(--orange);
      margin-bottom: -6px;
      font-size: 28px;
    }
    .ad-views {
      p {
        font-size: 20px;
        cursor: pointer;
        color: #707070;
        &:hover {
          color: var(--orange);
        }
      }
    }
    .adp-btns {
      border-radius: 10px;
      background-color: var(--orange);
      color: white;
      font-size: 14px;
      font-weight: 600;
      padding: 4px 20px;
    }
  }
}

@media (max-width: 480px) {
  #ad-card {
    padding: 10px !important;
    .main-img {
      img {
        height: 250px !important;
      }
    }
    .ad-car-details {
      h2 {
        font-size: 16px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 200px;
      }
    }
  }
}
