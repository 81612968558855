#alerts{
   padding:0 25px;
   .btn-custom{
       background-color: var(--orange);
       border-radius: 10px;
       color: white;
       font-weight: 500;
       padding: 6px 30px;
       &:hover{
           background-color: var(--primary);
           transition: 0.2s linear;
       }
   }
   .card{
    border-radius: 0;
    background-color: #F2F4F4;
    padding: 25px;
    .main-img{
        position: relative;
        img{
            width: 100%;
            height: 370px;
            object-fit: cover;
        }
        .active-btn{
            position: absolute;
            border-radius: 8px;
            padding: 5px 20px;
            background-color: #56BB51;
            color: white;
            font-weight: 500;
            left: 10px;
            top: 10px;
        }
        .edit-btn{
            position: absolute;
            border-radius: 8px;
            padding: 5px 20px;
            background-color: var(--orange);
            color: white;
            font-weight: 500;
            right: 10px;
            top: 10px;
        }
        .img-count{
            position: absolute;
            border-radius: 4px;
            padding:4px 10px;
            background-color: rgba(0,0,0,0.2);
            color: white;
            font-weight: 500;
            bottom: 10px;
            left: 10px;
        }
        .product-horizontal-label{
            position: absolute;
            background-color: rgba(0,0,0,0.2);
            color: white;
            font-weight: 500;
            bottom: 10px;
            left: 80px;
            padding: 4px 10px;
            border-radius: 4px;
        }
    }
    .ad-car-details{    
        h2{
            font-weight: 500;
            color: var(--orange);
            margin-bottom: -6px;
            font-size: 28px;
         
        }
        .ad-views{
            p{
                cursor: pointer;
                color: #707070;
                &:hover{
                    color: var(--orange);
                }
            }
        }
        .adp-btn{
            border-radius: 10px;
            background-color: var(--orange);
            color: white;
            font-size: 14px;
            font-weight: 600;
            padding:4px 20px ;
        }
    }
   }
}

@media(max-width:480px){
    #alerts{
        padding: 0 !important;
        .main-img{
            img{
                height: 170px!important;
            }
        }
        .ad-car-details{
            h2{
                font-size: 20px!important;
            }
        }
        .btn-custom{
            font-size: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 7px;
        }
        .web-view{
            display: none;
        }
    }
}
@media(min-width:768px){
    #alerts{
        .mobile-view{
            display: none;
        }
    }
}