.loader-overlay {
  position: fixed; /* Sit on top of the page content */
  // display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
  z-index: 10000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */

  .svg-class-name {
    text-align: center;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .progress {
    text-align: center;
    width: 25%;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    .progress-bar {
        background-color: var(--color-secondary);
        transition: width 1s linear;
    }
  }
}
