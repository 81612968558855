#payment-main {
  .btn-bottom {
    display: flex;
    justify-content: end;
    .btn-show-new-plan {
      background-color: var(--orange);
      border-radius: 8px;
      color: white;
      font-weight: 500;
      padding: 10px;
      &:hover {
        background-color: var(--primary);
      }
    }
  }
  .payment-functions-content {
    .payment-functions-inner {
      font-size: 14px;
    }
  }
  tr {
    td {
      font-size: 14px;
    }
  }
  table {
    tr {
      th {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  .btn-view-detail {
    background-color: var(--primary);
    color: white;
    font-weight: 500;
    font-size: 12px;
    &:hover {
      background-color: var(--orange);
      transition: 0.3s;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
// Modal -------------------------
.payment-view-modal {
  max-width: 50% !important;
}
.order-detail-main {
  margin-bottom: 20px;
  .order-content {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    .title {
      width: 40%;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
    }
    .content {
      width: 60%;
      display: inline-block;
      font-size: 14px;
    }
  }
}
// ------------------------------

@media only screen and (max-width: 700px) {
  .payment-view-modal {
    max-width: 100% !important;
  }
  .order-detail-main {
    .content {
      text-align: right;
    }
  }
}

@media (max-width: 480px) {
  .web-view {
    display: none;
  }
  .plan-history-mobile {
    border: 2px solid #ccc;
    border-radius: 15px;
    padding: 10px 0;
    margin-bottom: 10px;
    p {
      margin-bottom: 10px;
    }
  }
}
