:root {
  --orange: #f68300;
  --lightgrey: #ececec;
  --primary: #023047ed;
  --darkgrey: #707070;
  --bg-color: #fafbfe;
}

#new-messages {
  .custom-btn {
    background-color: var(--primary);
    color: white;
    border-radius: 10px;
  }
  .btn-primarys {
    background-color: var(--orange);
    color: white;
    border-radius: 10px;
  }
  .col-left {
    .col-inner-left {
      padding: 0;
      .msg-header {
        background-color: #fff;
        border-bottom: 1px solid #e3e9ea;
        padding: 10px 20px;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.06);
        z-index: 2;
        form {
          padding: 0;
          border: 2px solid #e3e9ea !important;
          display: flex;
          border-radius: 7px;
          p {
            margin-bottom: 0;
            align-self: center;
            margin-left: 8px;
            font-size: 20px !important;
          }
          .form-group {
            margin-bottom: 0;
            input {
              border: 0 !important;
              // width:180px;
              border-radius: 7px;
              padding: 20px;
              &:focus {
                border: 0 !important;
                box-shadow: none !important;
              }
            }
          }
          &:focus-within {
            border: 2px solid var(--orange) !important;
          }
        }
      }
      .msg-body {
        // padding: 20px;
        background-color: white;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.06);
        border-bottom: 1px solid #e3e9ea;
        height: 750px;
        overflow-y: auto;
        .msg-outside {
          display: flex;
          padding: 10px;
          img {
            width: 60px;
            height: 60px;
            object-fit: contain;
            border-radius: 100px;
            border: 2px solid #e3e9ea;
          }
          .msg-details {
            margin-left: 10px;
            align-self: center;
            color: #707070;
            p {
              font-size: 14px;
              text-overflow: ellipsis !important;
              overflow: hidden !important;
              white-space: nowrap;
              width: 110px;
            }
          }
        }
        .msg-time {
          display: flex;
          flex-direction: column;
          .badge {
            background-color: var(--primary);
            font-size: 10px;
            border-radius: 4px;
            color: white;
            padding: 5px 8px;
          }
        }
      }
    }
    .col-inner-right {
      background-color: #f2f4f4;
      padding: 0;
      .messages-only {
        overflow: scroll;
        height: 75vh;
        .msg-header {
          background-color: white;
          box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.06) inset;
          padding: 17px;
          border-bottom: 1px solid #e3e9ea;
          display: flex;
          justify-content: space-between;
          position: sticky;
          top: 0;
          z-index: 3;
          .profile {
            display: flex;

            .profile-details {
              padding-left: 10px;
              align-self: center;
              h5 {
                margin-bottom: 0;
                font-size: 18px;
              }
              p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 180px;
                margin-bottom: 0;
                font-size: 13px;
              }
            }
            img {
              width: 70px;
              height: 70px;
              border-radius: 100px;
              border: 2px solid #e3e9ea;
            }
          }
          .profile-btn {
            align-self: center;
            a {
              color: #707070;
              text-decoration: none;
              font-size: 16px;
              &:hover {
                color: var(--orange);
              }
            }
          }
        }
        .msg-box-1 {
          display: flex;
          padding: 30px 20px;
          position: relative;
          img {
            width: 70px;
            height: 70px;
            border-radius: 100px;
            border: 2px solid #e3e9ea;
          }
          .msg-user-1 {
            width: 100%;
            background-color: white;
            padding: 20px;
            margin-left: 15px;
            border-radius: 12px;
            p {
              font-size: 12px;
              margin-bottom: 0;
            }
            &::before {
              position: absolute;
              content: "";
              width: 0;
              height: 0;
              border-left: 9px solid transparent;
              border-right: 9px solid white;
              border-bottom: 9px solid transparent;
              border-top: 10px solid transparent;
              left: 87px;
            }
          }
        }
        .msg-box-2 {
          display: flex;
          padding: 30px 20px;
          position: relative;
          img {
            width: 70px;
            height: 70px;
            border-radius: 100px;
            border: 2px solid #e3e9ea;
          }
          .msg-user-1 {
            background-color: var(--orange);
            color: white;
            font-weight: 500;
            padding: 20px;
            margin-right: 15px;
            border-radius: 12px;
            p {
              font-size: 12px;
              margin-bottom: 0;
            }
            &::before {
              position: absolute;
              content: "";
              width: 0;
              height: 0;
              border-left: 9px solid var(--orange);
              border-right: 9px solid transparent;
              border-bottom: 9px solid transparent;
              border-top: 10px solid transparent;
              right: 87px;
            }
          }
        }
      }
      .text-area {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #707070;
        form {
          padding: 0;
          .form-group {
            margin-bottom: 0;
            textarea {
              background-color: #f2f4f4 !important;
              border: 1px solid transparent !important;
              &:focus {
                box-shadow: none !important;
              }
            }
          }
        }
        .msg-btn {
          background-color: var(--orange);
          border-radius: 12px;
          color: white;
          font-weight: 500;
          height: 40px;
          align-self: center;
          margin-right: 7px;
          &:focus {
            box-shadow: none !important;
          }
          &:hover {
            background-color: var(--primary);
          }
        }
      }
    }
  }
}
#new-chatbox{
  background-color: #f2f4f4;
}
// hiden scroll bar
.example::-webkit-scrollbar {
  display: none;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

@media (max-width: 480px) {
  .web-view {
    display: none;
  }
  .messages-only {
    height: 60vh !important;
  }
}
