.message-chat {
  display: block;
  height: 100vh;
  // overflow: scroll;
  padding: 20px 0px 0px 0px;
  .message-sample {
    padding: 15px 24px;
    background: var(--color-chat-light-green);
    width: 90%;
    position: relative;
    margin: 15px 0;
    .sender-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0px 0px 15px 0px;
      &:after {
        content: "";
        position: absolute;
        top: -15px;
        left: 20px;
        border-width: 0 15px 15px;
        border-style: solid;
        border-color: var(--color-chat-light-green) transparent;
        display: block;
        width: 0;
      }
      .sender-name {
        font-weight: 500;
        line-height: 30px;
        color: var(--text-color-primary);
        h4 {
          margin: 0px;
        }
      }
      .sender-date {
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        color: var(--color-text-gray);
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: var(--text-color-primary);
      margin: 0px;
    }
  }
  .sender {
    float: left;
    &::after {
      content: "";
      clear: both;
      display: table;
    }
  }
  .receiver {
    margin: 15px 0;
    float: right;
    background: var(--color-chat-light-blue);
    &::after {
      content: "";
      clear: both;
      display: table;
    }
    .sender-main {
      &::after {
        content: "";
        position: absolute;
        border-width: 0 15px 15px;
        border-style: solid;
        display: block;
        width: 0;
        border-color: var(--color-chat-light-blue) transparent;
        right: 20px;
        top: -15px;
        left: auto;
        bottom: auto;
      }
    }
  }

  .typing-user {
    text-align: right;
    margin: 10px 15px;
  }
}

.left-img {
  position: absolute;
  right: 10px !important;
}

.msg-user-2 {
  background-color: var(--orange);
  color: white;
  font-weight: 500;
  padding: 20px;
  border-radius: 12px;
  left: 40px;
  width: 70%;
  position: relative;
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
  &::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 9px solid var(--orange);
    border-right: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-top: 10px solid transparent;
    right: -18px;
  }
}
