.featured-ad-plans-subscribed-main {
  // padding: 50px 0px;
  // background: var(--color-background-all);

  .featured-container {
    // background: var(--color-white);
    // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    // height: 100%;
    // padding: 20px;

    .featured-plans {
      background: var(--color-white);
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      height: 100%;
      padding: 20px;
    }

    .bulk-ads {
      margin: 25px 0px 0px 0px;
    }

    .single-plans {
      // border-bottom: 1px solid var(--border-color-primary);
      .row {
        margin-bottom: 20px;
      }
    }
    .featured-plans-wrapper {
      padding: 20px 20px 20px 20px;
      .featured-panel-container {
        // background: var(--color-background-all);
        .featured-plans-panel {
          // background: var(--color-background-all);
          position: relative;
          overflow: hidden;
          margin: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
          border-radius: 12px;
          background-clip: padding-box;
          border: 1px solid transparent;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          transition: all 0.2s ease-in-out;
          &:hover {
            transform: scale(1.05);
          }

          .discount-percentage {
            width: 0;
            height: 0;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
            border-bottom: 50px solid var(--color-dark-red);
            position: absolute;
            left: -32.5px;
            top: -7px;
            transform: rotate(-45deg);
            line-height: 16px;
            text-align: center;
            span {
              font-size: 14px;
              display: inline-block;
              position: relative;
              top: 15px;
              left: -12px;
              font-weight: 500;
              color: var(--color-white);
            }
          }
          .panel-heading {
            padding: 15px;
            border-radius: 0;
            background-clip: padding-box;
            border-bottom: 1px solid #e5e4e3;
            text-align: center;

            h3 {
              margin: 0;
              font-size: 18px;
              color: var(--color-primary);
              font-weight: 500;
            }
          }

          .panel-body {
            padding: 15px;
            background-color: #f8f8f8;
            text-align: center;
            border-color: #ededed;
            font-family: "Roboto Slab";
            font-weight: 300;
            line-height: 1.6;
            letter-spacing: 0px;
            font-style: normal;

            .price {
              position: relative;

              .currency {
                position: relative;
                color: #505050;
                top: -20px;
                font-size: 21px;
              }

              .integer-part {
                font-size: 22px;
                display: inline;
                font-weight: 500;
                color: var(--color-secondary);
                line-height: normal;
              }

              .integer-part-prev {
                font-size: 16px;
                display: inline;
                font-weight: 400;
                color: var(--color-light-dark);
                line-height: normal;
                text-decoration: line-through;
                margin-left: 5px;
              }
              .time {
                margin-left: 0;
                position: relative;
                font-style: italic;
                color: #888;
                font-size: 11px;
              }
            }
          }

          .list-group {
            margin: 0;
            padding: 0;
            .remaining-note {
              padding: 10px;
              span.note {
                font-weight: 600;
                font-size: 20px;
                color: var(--color-dark-red);
              }
              span {
                font-weight: 600;
                font-size: 16px;
              }
            }
            .list-group-item {
              display: block;
              position: relative;
              padding: 15px;
              margin: 0;
              background: 0 0;
              border-radius: 0;
              border-top: 1px solid #e5e4e3;
              text-align: center;
              color: #646464;
              border-right: 0px;
              border-left: 0px;
              p {
                color: var(--text-color-primary);
                margin: 0px;
                font-size: 14px;

                i {
                  color: var(--color-secondary);
                  font-size: 14px;
                  margin: 0px 5px 0px 0px;
                }
              }
            }
          }

          .panel-footer {
            padding: 25px 15px;
            border-radius: 0;
            background-clip: padding-box;
            border-color: #e5e4e3;
            text-align: center;

            .plans-button {
              padding: 13px 40px;
              line-height: 17px;
              font-size: 17px;
              background: var(--orange);
              color: #ffffff;
              border-radius: 10px;
              border: 0;
              i.fa.fa-check {
                color: var(--color-success);
                margin-right: 2px;
              }
              &:hover {
                background: var(--primary);
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }

    .plan-center {
      box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.08);
      padding: 9px;
    }

    h2 {
      margin: 0px 0px 20px 0px;
      color: var(--text-color-primary);
      font-size: 24px;
    }

    .main-title {
      display: inline-block;
      width: 100%;
      margin: 0px 0px 7px 0px;
    }
    .subscribe-div {
      text-align: center;
      .btn-subscribe {
        margin-bottom: 50px;
        border-radius: 12px;
        padding: 10px 30px;
        background: var(--color-secondary);
        border: none;
        &:hover {
          background: var(--color-primary) !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .featured-ad-plans-subscribed-main .featured-container {
    padding: 15px;
  }
  .featured-ad-plans-subscribed-main .featured-container .featured-plans-wrapper {
    padding: 15px;
  }
}
@media (max-width: 767px) {
  .featured-ad-plans-subscribed-main .featured-container .featured-plans-wrapper .featured-panel-container .featured-plans-panel .panel-footer .plans-button {
    font-size: 14px;
    line-height: 20px;
    padding: 15px 32px;
  }
}
@media (max-width: 575px) {
  .featured-ad-plans-subscribed-main {
    padding: 50px 15px;
  }
}
