.users-auction-sheets-main {
  padding: 20px;
  table thead tr {
    background: var(--color-primary);
    th {
      color: var(--color-white);
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      vertical-align: middle;
      padding: 15px 20px 15px 20px;
      border: none;
      border-right: 1px solid var(--color-white);
    }
    @media screen and (max-width: "600px") {
      th {
        font-size: 12px;
      }
    }
  }
  tbody tr td a {
    background: var(--color-secondary);
    color: var(--color-white);
    padding: 8px;
    &:hover {
      text-decoration: none;
    }
  }

  button.btn.auction-cancel {
    background: var(--color-dark-red);
    color: var(--color-white);
    display: block;
    float: right;
  }
  @media screen and (max-width: "600px") {
    tbody {
      font-size: 12px;
      //   padding: 4px;
    }
    td,
    th {
      padding: 0.5rem;
    }
    table {
      display: block;
      width: 100%;
      overflow-x: auto;
    }
    button.btn.auction-cancel {
      font-size: 12px;
    }
  }
}
