.footer {
  background-color: var(--color-primary);
  border-bottom: 1px solid #97979736;
  color: #fff;

  .follow-us{
    h5{
      color: #fff;
    }
  }
}
.footer-content h2 {
  font-size: 17px;
  color: #ffffff;
  font-weight: 700;
  // margin-bottom: 20px;
}
.row.col-one {
  padding: 30px 0px 0px 0px;
  @media(max-width: 480px){
    padding: 40px 0 20px;
  }
}
.footer-content ul {
  padding-left: 0px;
}
.footer-content li {
  list-style-type: none;
  line-height: 22.99px;
}
.footer-content li a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  span {
    text-transform: capitalize;
  }
}
.address {
  display: inline-block;
  margin-bottom: 5px;
}
.address .icon {
  float: left;
  width: 25px;
  margin-right: 10px;
  font-size: 20px;
}
.main-address {
  float: left;
  width: calc(100% - 35px);
}
.address .icon i {
  color: var(--color-secondary);
}
.main-address-heading {
  font-size: 15px;
  color: #a0b8e3;
  font-weight: 400;
}
.main-address-content {
  color: #848484;
  font-weight: 400;
  font-size: 15px;
  button {
    color: #fff;
    font-weight: 400;
    font-size: 15px;
    padding: 0px;
    &:hover {
      color: #848484;
    }
  }
}
.row.col-two {
  padding-bottom: 20px;
}
.mobile-footer {
  display: none;
}


@media (max-width: 1199px) {
  .footer-content {
    h2 {
      font-size: 15px;
    }
    .newsletter {
      width: 100%;
      .newsletter-content {
        margin: 0 20px;
        h1 {
          font-size: 15px;
        }
        .newsletter-input {
          background: transparent;
          input {
            width: 100%;
            border: 1px solid var(--color-secondary);
          }
          button {
            padding: 8px 15px;
            font-size: 15px;
            margin: 10px 0 0;
          }
        }
      }
    }
  }
  .address {
    .icon {
      width: 20px;
      font-size: 15px;
      margin: 0;
    }
    .main-address {
      width: calc(100% - 20px);
      .main-address-content {
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 991px) {
  .col-md-2.footer-content {
    width: 100%;
    max-width: 100%;
    // flex: 0 0 33.3%;
  }
  .col-md-4.footer-content {
    // flex: 0 0 66.7%;
    width: 100%;
    // max-width: 66.7%;
  }
  .footer-content {
    margin-bottom: 20px;
    h2 {
      font-size: 17px;
    }
    li {
      a {
        font-size: 15px;
      }
    }
    .newsletter {
      width: 100%;
      .newsletter-content {
        margin: 0 30px;
        h1 {
          font-size: 17px;
        }
        .newsletter-input {
          background: transparent;
          width: 100%;
          input {
            width: 100%;
            border: 1px solid var(--color-secondary);
          }
          button {
            padding: 10px 15px;
            font-size: 16px;
          }
        }
      }
    }
  }
  .address {
    .icon {
      width: 25px;
      font-size: 20px;
      margin-right: 10px;
    }
    .main-address {
      width: calc(100% - 35px);
      .main-address-content {
        font-size: 15px;
      }
    }
  }
}
@media (max-width: 767px) {
  .col-md-2.footer-content {
    width: 100%;
    max-width: 100%;
    // flex: auto;
  }
  .col-md-4.footer-content {
    // flex: auto;
    width: 100%;
    max-width: 100%;
  }
  .copyrights-text {
    max-width: 100%;
    span {
      padding: 0 !important;
    }
  }
  .address {
    display: block;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: table;
    }
  }
  .newsletter-button {
    .col-md-5 {
      width: calc(50% - 0.5rem);
      float: left;
    }
  }
}

@media (max-width: 480px) {
  .footer-content {
    margin-bottom: 10px;
  }
  .newsletter {
    margin-top: -30px;
    margin-bottom: 24px;

  }
  .main-footer {
    h2 {
      font-size: 17px !important;
      white-space: nowrap;
    }
  }
}
