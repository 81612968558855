#find-sheet {
  .loading-animation {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 2s linear infinite;
    width: auto;
    height: 150px;
    object-fit: contain;
    margin: 20px;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .btn-custom-sheet {
    background-color: var(--primary);
    color: white;
    border-radius: 10px;
    padding: 15px 117px;
    font-weight: 600;
    margin-bottom: 30px;
    &:hover {
      background-color: var(--orange);
      transition: 0.2s linear;
      color: white;
    }
  }
  .content-auction-details {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #c3c3c3;
    padding: 18px 20px;
    background-color: white;
    &:hover {
      background-color: rgba(255, 166, 0, 0.339);
      border: 1px solid var(--orange);
      transition: 0.2s linear;
    }
  }
  .img-content-detail {
    display: flex;
    align-self: center;
    margin-left: 50px;
    img {
      margin-top: -4px;
      width: auto;
      height: 26px;
    }
  }
}

@media (max-width: 425px) {
  .btn-custom-sheet {
    padding: 15px 13px !important;
  }
  .content-auction-details {
    margin: 0 3px;
  }
}
