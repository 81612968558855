.bike-modal-main {
  padding: 0px;

  .headings {
    display: flex;

    .main-heading {
      font-size: 23px;
      font-weight: 700;
      color: var(--text-color-primary);
      text-align: center;
      line-height: 45px;
      padding-bottom: 15px;
      padding-top: 15px;
      width: 100%;
      border-radius: 15px 15px 0px 0px;
      background: var(--color-background-all);
      .optional {
        font-size: 12px;
        color: var(--color-light-dark);
        line-height: 1;
      }
    }
  }

  .info-main {
    margin: 0px;

    .col-md-4 {
      padding: 0px;
      border-right: 1px solid var(--border-color-primary);
    }

    .carinfo-content {
      max-height: 400px;
      overflow: scroll;
      margin: 0px 10px;

      .text.heading {
        font-weight: 900 !important;
      }

      .text {
        float: left;
        padding: 12px 0px 12px 8px;
        font-size: 14px;
        color: var(--text-color-primary);
        font-weight: 400;
      }

      .info-section {
        display: inline-block;
        vertical-align: top;
        width: 100%;

        &:hover {
          background: var(--color-primary);
          cursor: pointer;
        }

        &:hover div {
          color: var(--color-white) !important;
        }

        .text.heading {
          font-weight: 900 !important;
        }

        .text {
          float: left;
          padding: 12px 0px 12px 8px;
          font-size: 14px;
          color: var(--text-color-primary);
          font-weight: 400;

          .make-logo {
            width: 40px;
            float: left;
            height: 40px;
            margin: -6px 5px -13px -6px;
            background-position: 0 0 !important;
            background-size: 100% !important;
          }
        }

        .arrow {
          float: right;
          padding: 10px 26px 10px 0px;
        }

        .variant-text {
          padding-left: 25px;

          .variant-model {
            font-weight: 500;
            font-size: 14px;
            padding: 4px 0px 0px 0px;
          }

          .variant-type {
            font-size: 11px;
            font-weight: 400;
            padding: 0px 0px 4px 0px;
          }
        }
      }
    }

    .active-option {
      background: var(--color-primary);
      color: var(--color-white);

      div {
        color: var(--color-white) !important;
      }
    }
    .deactive {
      .text {
        opacity: 0.5;
      }
      .arrow {
        opacity: 0.5;
      }
      &:hover div {
        opacity: 1 !important;
      }
    }
    .info-third {
      border: 0px;
    }
  }

  .active-text {
    color: var(--color-secondary) !important;
  }

  .carinfo-button {
    border-radius: 0px 0px 15px 15px;
    text-align: center;
    background: var(--color-background-all);

    button {
      border-radius: 0px;
      padding: 21px 154px;
      color: var(--color-white);
      background: var(--color-secondary);
      margin: 18px 0px 15px 0px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.modal-dialog.postyouradd-carinfo {
  max-width: 100%;
}
