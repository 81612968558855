.custom-paginate-main {
  li.previous.disabled {
    align-self: center;
    a {
      color: var(--color-grey-disabled);
      cursor: not-allowed;
    }
    a:hover {
      text-decoration: none;
    }
  }
  li.next {
    align-self: center;
    a {
    }
  }
  li.next.disabled {
    align-self: center;
    a {
      background-color: var(--color-grey-disabled);
      cursor: not-allowed;
    }
    a:hover {
      text-decoration: none;
    }
  }
  li {
    a {
      border: 1px solid #ececec;
      font-size: 16px !important;
      border-radius: 4px;
      margin: 0 4px;
      padding: 4px 10px !important;
    }
  }
}
@media (max-width: 480px) {
  .custom-paginate-main .pagination li a {
    padding: 5px;
  }
  li.previous.disabled {
    align-self: center;
    display: none!important;
    a {
      color: var(--color-grey-disabled);
      cursor: not-allowed;
    }
    a:hover {
      text-decoration: none;
    }
  }
  li.next {
    display: none!important;
    align-self: center;
  }
  li.next.disabled {
    display: none!important;
    align-self: center;
    a {
      color: var(--color-grey-disabled);
      cursor: not-allowed;
    }
    a:hover {
      text-decoration: none;
    }
  }
}
