.review-modal-main {
  padding: 40px 35px 74px 35px;
  .modal-headings {
    max-width: 752px;
    margin: 0 auto;
    text-align: center;
    border-bottom: 1px solid var(--border-color-primary);
    h2 {
      color: var(--text-color-primary);
      line-height: 45px;
      font-weight: 700;
      margin: 0px 0px 10px 0px;
    }
    p {
      color: var(--text-color-primary);
      font-weight: 500;
      font-size: 16px;
      margin: 0px;
      padding: 0px 0px 17px 0px;
    }
  }
  .review-process {
    margin: 50px 0px 50px 0px;
    span {
      margin: 0px 40px 0px 0px;
      font-size: 16px;
      font-weight: 500;
      color: var(--text-color-primary);
      width: 125px !important;
      display: inline-block;
    }
  }
  .review-form {
    label {
      color: var(--text-color-primary);
      font-size: 16px;
      font-weight: 400;
      margin: 0px 0px 13px 0px;
    }
    .review-input {
      display: inline-block;
      width: 100%;
      margin-bottom: 30px;
      input {
        border-radius: 12px;
        background: var(--color-light-gray);
        border: 1px solid var(--border-color-primary);
        height: 60px;
        color: var(--text-color-primary);
        box-shadow: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        padding-left: 14px;
        &:focus{
          border: 1px solid var(--orange);
          box-shadow: none!important;
        }
      }
      .form-group {
        margin: 0px;
      }
      .review-form-left {
        float: left;
        width: 47%;
        margin-right: 6%;
      }
      .review-form-right {
        float: left;
        width: 47%;
      }
    }
    .review-textarea {
      margin: 0px 0px 30px 0px;
      textarea {
        background: var(--color-background-all);
        border-radius: 12px;
        font-size: 14px;
        font-weight: 500;
        color: var(--text-color-primary);
        &:focus{
          border: 1px solid var(--orange);
          box-shadow: none!important;
        }
      }
    }

    .review-submit {
      .submit {
        background: var(--color-secondary);
        color: var(--color-white);
        font-size: 14px;
        padding: 20px 74px;
        border-radius: 12px;
        margin: 0px 20px 0px 0px;
      }
      .cancel {
        background: var(--color-primary);
        color: var(--color-white);
        font-size: 14px;
        padding: 20px 74px;
        border-radius: 12px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .review-modal-main .review-form .review-input .review-form-left {
    width: 100%;
  }
  .review-modal-main .review-form .review-submit .submit {
    display: block;
    margin: 0px 0px 15px 0px;
    font-size: 14px;
    padding: 15px 50px;
  }
  .review-modal-main .review-form .review-submit .cancel {
    font-size: 14px;
    padding: 15px 50px;
  }
}

