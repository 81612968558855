.newsletter {
  background-color: var(--color-secondary);
  padding: 18px 0px 15px 0px;
  border-radius: 15px;
  width: 450px;
}
.newsletter-input {
  background-color: #fff;
  display: inline-block;
  border-radius: 10px;
  // margin-bottom: 20px;
}
.newsletter-content h1 {
  font-size: 17px;
  color: #ffffff;
  font-weight: 900;
  line-height: 32px;
}
.newsletter-input-new input {
  padding: 25px 20px;
  font-size: 14px;
  font-weight: 300;
  width: 700px;
  float: left;
  &:focus {
    box-shadow: none;
  }
}
.newsletter-content {
  margin: 0px 30px;
  .newsletter-msg {
    h5 {
      color: var(--color-white);
    }
  }
}
.newsletter-button button {
  background-color: black;
}
.newsletter-input-new button {
  background-color: #f1833b;
  padding: 10px 30px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  margin-left: 15px !important;
  font-size: 20px;
  margin: 1px 5px;
  &:hover {
    color: white;
  }
}
// .app-download-mobile {
//   display: none;
// }
.newsletter-heading {
  margin-bottom: 20px;
  .invalid-feedback.d-block {
    position: unset !important;
    margin-top: 5px;
  }
}
@media (max-width: 767px) {
  .app-download-mobile {
    display: block;
    margin: 0 30px;
  }
  // .newsletter-content {
  //   display: none;
  // }
  .newsletter {
    padding: 20px 0;
  }
  .newsletter-download h3 {
    color: var(--color-white);
    margin-bottom: 15px;
  }
}
.follow-us {
  h5 {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin: 20px 0px 0px 0px;
    line-height: auto;
  }
  ul.list-unstyled.list-inline.networks.primary-lang {
    direction: ltr;
    line-height: 20px;
  }
  ul.list-unstyled.list-inline.networks.primary-lang li {
    float: left;
    margin: 20px 6px 0px 0px;
    line-height: 16px;
    padding: 0;
  }
  ul.list-unstyled.list-inline.networks.primary-lang li button {
    transition: all 0.25s linear;
    // border-radius: 50%;
    background-color: white;
    box-shadow: 1px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: block;
    margin: 4px 6px 4px 0;
    padding: 6px 10px;
    width: 38px;
    height: 38px;
    &:hover {
      color: var(--color-secondary);
    }
  }
  ul.list-unstyled.list-inline.networks.primary-lang li:last-child{
    button{
      margin-right: 0;
    }
  }
}
.icon-color {
  color: #f1833b !important;
}
.btn-custom-1 {
  background-color: var(-orange);
  border-radius: 10px;
  color: white;
  padding: 7px 20px;
}
@media (max-width: 480px) {
  .newsletter-input-new button {
    background-color: #f1833b;
    padding: 10px 20px;
    color: #ffffff;
    font-weight: 600;
    margin-left: 8px !important;
    font-size: 12px !important;
    margin: 1px 5px;
    &:hover {
      color: white;
    }
  }
}
