.mobile-car-info-modal-main {
  max-width: 100% !important;
  // margin-left: 20px;
  // margin-right: 20px;
  .carinfo-button {
    text-align: center;
    border-radius: 0px 0px 15px 15px;

    button {
      border-radius: 10px;
      padding: 7px 20px;
      color: var(--color-white);
      background: var(--color-secondary);
      margin: 18px 0px 15px 0px;
      font-size: 16px;
      font-weight: 700;
      &:hover {
        background: var(--color-primary);
        cursor: pointer;
      }
    }
  }
  .css-yk16xz-control {
    border-radius: 10px !important;
  }
  .css-1pahdxg-control {
    border-radius: 10px;
  }
}
