.breadcrumb-main {
  font-size: 14px;
  font-weight: 400;
  min-height: 42px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  background-color: #0C3148;
  color: var(--color-light-gray);
  .container > span {
    margin-left: 15px;
    display: block;
  }
  .container > span > span:last-child {
    display: inline-block;
  }
  .container {
    padding: 10px 0;
    span {
      a {
        color: var(--color-light-gray);
      }
      b {
        margin: 0px 12px;
      }
    }
  }

  .breadcrumb {
    background: transparent;
    padding: 12px 0;
    margin: 0;

    .breadcrumb-item {
      color: var(--color-white);
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }
    .breadcrumb-item a {
      color: var(--color-white);
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      &:hover {
        color: var(--color-secondary);
      }
    }
    .breadcrumb-item + .breadcrumb-item::before {
      content: "|";
      color: var(--color-white);
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }
  }
}
@media only screen and (max-width: 480px) {
  .breadcrumb-main {
    height: auto;
  }
  .breadcrumb-main .container span {
    margin-left: 0px;
  }
  .breadcrumb-main .container {
    padding: 10px 10px;
  }
}
