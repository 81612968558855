.multiimagemodal-main {
  .multi-image-header {
    max-width: 540px;
    padding: 54px 0px 0px 0px;
    margin: 0 auto;
    text-align: center;
    border-bottom: 1px solid var(--border-color-primary);
    h2 {
      font-size: 23px;
      line-height: 45px;
      font-weight: 700;
      color: var(--color-secondary);
      padding: 0px 0px 16px 0px;
      border-bottom: 4px solid var(--color-secondary);
      max-width: 320px;
      margin: 0 auto;
    }
    i {
      font-size: 27px;
      margin: 0px 10px 0px 0px;
    }
  }
  .header-text {
    padding: 21px 0px 0px 0px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 14px;
    color: var(--color-text-gray);
  }
  .multiple-images {
    padding: 30px 0px 30px 0px;
    display: inline-block;
    width: 100%;
    .images-row {
      display: inline-block;
      width: 100%;
      padding: 0% 1% 0% 2%;
      > div {
        width: 24%;
        margin: 0% 1% 0% 0%;
        float: left;
      }
    }
    .imagedragged {
      display: block !important;
    }
    .col-md-3 {
      padding: 0px 7px;
    }
    [data-rbd-drag-handle-context-id="0"] {
      cursor: move;
    }
    // .multiimage-dragged {
    //   padding: 0px !important;
    //   margin: 0px !important;
    .multi-image-content {
      border: 1px solid var(--border-color-primary);
      margin: 0px 0px 20px 0px;
      background: var(--color-white);
      .image-main {
        position: relative;
        height: 100px;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          transition: transform 0.5s;
          cursor: move;
        }
        i.far.fa-star {
          cursor: pointer;
        }
      }
      .image-main-icon {
        color: var(--color-white);
        padding: 5px;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 9px;
        right: 9px;
        span {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
        }
        i {
          padding: 0px 0px 0px 0px;
          font-size: 20px;
          color: var(--color-success);
        }
      }
      .multiple-icons {
        display: flex;
        padding: 14px 20px;
        .previous-icon {
          padding: 0px 22px 0px 0px;
          img {
            cursor: pointer;
          }
        }
        .icon-text {
          font-size: 18px;
          line-height: 21px;
          padding: 0px 22px 0px 0px;
          font-weight: 400;
          color: var(--color-text-gray);
        }
        .forward-icon {
          padding: 0px 12px 0px 0px;
          img {
            cursor: pointer;
          }
        }
        .arrow-disabled {
          // color: var(--color-grey-disabled);
          color: var(--color-primary);
          cursor: not-allowed;
          opacity: 0.5;
        }
        .arrow-enabled {
          color: var(--color-primary);
          // color: var(--color-text-gray);
          cursor: pointer;
        }
        .right-border {
          border-right: 1px solid var(--border-color-primary);
          margin: 0px 12px 0px 0px;
        }
        .rotate-icon {
          padding: 0px 12px 0px 0px;
          img {
            cursor: pointer;
          }
        }
        .delete-icon {
          img {
            cursor: pointer;
          }
        }
      }
    }
    // }

    .image-placeholder {
      border: 2px dashed var(--border-color-primary);
      margin: 0px 0px 20px 0px;
      background: var(--color-white);
      height: 259px;
      .image-placeholder-inner {
        width: 100%;
        height: 100%;
        position: relative;
        .upload-photos {
          position: absolute;
          height: 100%;
          z-index: 1000;
          cursor: pointer;
          width: 100%;
          padding-bottom: -6px;
          opacity: 0;
        }
        .image-placeholder-plus {
          margin: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          .fa,
          .fas {
            color: var(--color-primary);
          }
        }
        .image-placeholder-bottom {
          position: absolute;
          bottom: 0;
          padding: 5px 15px;
          color: var(--border-color-primary);
          background-color: white;
          line-height: 25px;
          display: block;
          width: 100%;
          .remainig {
            float: right;
            font-size: 24px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .multiimage-buttons {
    text-align: center;
    // padding: 0px 0px 70px 0px;
    .more-photo {
      position: relative;
      font-size: 16px;
      background: var(--color-secondary);
      font-weight: 700;
      border-radius: 0px;
      padding: 18px 32px;
      color: var(--color-white);
      margin: 0px 20px 0px 0px;
      img {
        width: 24px;
        margin: 0px 10px 0px 0px;
      }
      .upload-photos {
        position: absolute;
        height: 62px;
        z-index: 1000;
        cursor: pointer;
        right: -1px;
        top: -1px;
        padding-bottom: -6px;
        opacity: 0;
      }
    }
    button.btn.finished {
      font-size: 16px;
      background: var(--color-primary);
      font-weight: 700;
      border-radius: 0px;
      padding: 18px 60px;
      color: var(--color-white);
      i {
        font-size: 24px;
        vertical-align: bottom;
        margin: 0px 10px 0px 0px;
      }
    }
  }
  .pointer {
    cursor: pointer;
  }
}

.modal-dialog.image-editor-modal {
  // .modal-content {
  max-width: 87% !important;
  // }
}
@media only screen and (max-width: 991px) {
  .multiimagemodal-main .multiple-images .images-row > div {
    width: 49%;
  }
}

@media only screen and (max-width: 767px) {
  .multiimagemodal-main .multiimage-buttons .more-photo {
    display: block;
    margin: 0 auto;
    width: 260px;
    transform: scale(0.7);
  }
  .multiimagemodal-main .multiimage-buttons button.btn.finished {
    margin-top: 0px;
    width: 260px;
    transform: scale(0.7);
  }
  .multiimagemodal-main .multi-image-header {
    max-width: 450px;
  }
  .multiimagemodal-main .header-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .modal-dialog {
    height: 100vh !important;
    .modal-content {
      transform: translateY(0%);
      width: 100% !important;
      .modal-body {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
    .modal-title {
      font-size: 20px !important;
    }
  }
  .multiimagemodal-main .multiple-images .images-row > div {
    // width: 100%;
    // margin-right: 0px;
    .image-placeholder {
      height: 143px;
      margin-bottom: 5px;
    }
    .multi-image-content {
      margin-bottom: 5px;
    }
    .multiple-icons {
      padding: 8px 3px;
    }
    .previous-icon {
      padding-right: 0 !important;
    }
    .forward-icon {
      padding-right: 4px !important;
    }
    .icon-text {
      padding-right: 0 !important;
      font-size: 12px !important;
    }
    .right-border {
      margin-right: 6px !important;
    }
    .rotate-icon {
      padding-right: 6px !important;
      img {
        width: 25px;
      }
    }
    .delete-icon {
      img {
        width: 18px;
      }
    }
  }
  .multiimagemodal-main .multiple-images .images-row {
    padding: 0% 5% 0% 5%;
  }
  .multiimagemodal-main .header-text {
    font-size: 12px;
  }
  .multiimagemodal-main .multi-image-header h2 {
    font-size: 16px;
    line-height: 28px;
    max-width: 200px;
  }
  .multiimagemodal-main .multi-image-header {
    max-width: 245px;
  }
  .multiimagemodal-main .multiimage-buttons .more-photo {
    width: 235px;
    font-size: 12px;
  }
  .multiimagemodal-main .multiimage-buttons button.btn.finished {
    width: 235px;
    font-size: 12px;
  }
  .modal-dialog {
    margin: 0.5rem auto;
    max-width: 100% !important;
  }
}
