:root {
  --orange: #f68300;
  --lightgrey: #ececec;
  --primary: #023047ed;
  --darkgrey: #707070;
  --bg-color: #fafbfe;
}
#right-menu {
  .left-top-status:last-child {
    margin-bottom: 0px;
  }
  .left-top-status {
    margin-bottom: 15px;
    button.btn {
      width: 100%;
      padding: 25px 20px;
      border: none;
      border-radius: 12px;
      background: white;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      .status-left {
        font-size: 18px;
        line-height: 14px;
        color: var(--color-text-gray);
      }
      .status-right {
        margin-left: 20px;
        font-size: 18px;
        line-height: 14px;
        font-weight: 500;
        color: var(--color-text-gray);
      }
      &:hover {
        // border: 1px solid var(--orange);
        background: var(--orange);
        transition: 0.3s;
        .status-left {
          font-weight: bold;
          color: white !important;
        }
        .status-right {
          font-weight: bold;
          color: white !important;
        }
      }
      &:focus {
        box-shadow: none !important;
      }
    }

    &.active {
      button.btn {
        background: var(--orange);
        color: white !important;
      }
      .status-left {
        color: white !important;
      }
      .status-right {
        color: white !important;
      }
    }
  }
}
.btn-msgs {
  border-radius: 10px;
  color: var(--orange);
  font-weight: 600;
  font-size: 17px;
  padding: 12px;
  margin-top: -7px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 480px) {
  #right-menu {
    display: flex;
    .left-top-status {
      width: 100%;
      button.btn {
        padding: 7px 10px !important;
        text-align: center !important;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        white-space: nowrap;
        .status-left {
          margin: 0 !important;
          font-size: 12px !important;
        }
        .status-right {
          font-size: 10px;
          margin-left: 5px;
        }
        .status-right.active {
          color: #fff !important;
        }
      }
    }
    .left-top-status:nth-child(2) {
      margin: 0 6px;
    }
  }
}
