.copyrights {
  background-color: var(--color-primary);
  .copyrights-text {
    // max-width: 420px;
    margin: 0 auto;
    text-align: center;
    padding: 15px 0px;
    // opacity: 0.5;
    @media(max-width: 480px){
      padding: 30px 0 50px;
    }
    span {
      color: var(--color-white);
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
    }
    a {
      color: var(--color-white);
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
    }
    .padding-center {
      img {
        width: 190px;
      }
    }
    .for-mobile {
      display: none;
    }
    .for-desktop{
      button{
        &:hover {
          text-decoration: none;
        }
      }
    }
    .reserved-text{
      text-align: left;
      span{
        p{
          margin: 0px;
        }
      }
    }
    .term-service{
      text-align: right;
      span{
        padding:0px 5px;
      }
    }
  }
}
@media (max-width: 767px) {
  .copyrights {
    .copyrights-text {
      padding-bottom: 35px;
      .col-md-4 {
        margin-bottom: 10px;
      }
      .for-mobile {
        display: block !important;
      }
      .for-desktop {
        display: none;
      }
      .reserved-text{
        text-align: center;
      }
      .term-service{
        text-align: center;
      }
    }
  }
}
// .copyrights .padding-center{
// padding: 0pc 30px;
// }

