.home-page {
  position: relative;
  .header {
    // background-image: url(../../../assets/Images/home-banner.jpg);
    background-color: #1c3b76;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    // min-height: 630px;
  }
  .parts-accessories {
    margin-top: 0 !important;
  }
}
.active-border {
  border-bottom: 4px solid white;
}
.popular-makes-border {
  border-bottom: 1px solid #ececec !important;
}
.popular-makes-border.nissan {
  border-bottom: 1px solid transparent !important;
}
.popular-makes-border.Gujranwala {
  border-bottom: 1px solid transparent !important;
}
.header {
  background-color: var(--color-primary);
  .disable-cart {
    cursor: not-allowed;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
    transform: none !important;
    top: 42px !important;
    border-radius: 0;
    border: 0;
  }
  .column-4 {
    width: 597px;
  }
  .column-3 {
    width: 885px;
    padding-top: 0;
  }
  .column-2 {
    width: 737px;
  }
  .column-1 {
    width: 270px;
  }
  .border-right-1px {
    border-right: 1px solid var(--border-color-primary);
  }
  nav.navbar {
    padding: 0px 0;
    .navbar-brand {
      padding: 0 !important;
    }
    .navbar-nav {
      align-items: center;
      .menu-links:nth-last-child(2).show {
        .show {
          display: none;
        }
      }
      .menu-links {
        border-bottom: 1px solid transparent;

        .more-dropdown {
          left: auto !important;
          right: 0 !important;
        }
        .dropdown-menu.dropdown-menu-right {
          padding: 10px 20px;
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          background-clip: border-box;
          .dropdown-item {
            padding: 0;
            .private-dropdown-content {
              .dropdown-ads {
                a {
                  text-decoration: none;
                  color: var(--text-color-primary);
                  font-size: 15px;
                  font-weight: 500;
                  padding: 10px 18px;
                  opacity: 0.8;
                  i {
                    margin-right: 20px;
                  }
                  &:hover {
                    color: var(--color-secondary);
                    opacity: 1;
                  }
                }
              }
            }
          }
          .dropdown-item:focus,
          .dropdown-item:hover {
            background-color: transparent;
          }
        }
        a.nav-link {
          display: block;
          height: 45px;
          padding: 0 13px;
          color: #434343;

          span {
            color: var(--color-white) !important;
            font-weight: 500;
            font-size: 15px;
            line-height: 44px;
            display: block;
          }
        }
        &:hover {
          background-color: var(--color-white);
          border-bottom: 1px solid;
          a.nav-link {
            span {
              color: var(--color-secondary) !important;
            }
          }
        }
      }
      .nav-item {
        padding: 0 10px;
        a.nav-link {
          color: var(--color-white) !important;
          font-weight: 500;
          font-size: 15px;
          padding: 0 !important;
          &:hover {
            color: var(--color-secondary) !important;
          }
        }
      }
      .dropdown.nav-item {
        margin-left: 25px;
        padding: 0;
        display: inline-block;
        a {
          background-color: var(--color-secondary);
          padding: 10px 20px !important;
          width: 140px;
          text-align: left;
          border-radius: 4px;
          &:focus,
          &:hover {
            color: var(--color-white) !important;
          }
          &:after {
            margin: 10px 0 0 0;
            float: right;
          }
          .cart-image {
            margin: 0;
            float: left;
          }
          span {
            font-size: 16px;
            font-weight: 700;
          }
        }
        .dropdown-menu {
          border-radius: 0;
          border: none;
          width: 175px;
          margin: 0;
          border-bottom: 5px solid var(--orange);
          background-clip: border-box;
          button {
            &:focus {
              outline: none;
            }
            &:active {
              background-color: #fff;
            }
          }
          button.dropdown-item {
            padding: 10px 10px 10px 16px !important;
            border-bottom: 1px solid var(--border-color-primary);
            &:last-child {
              border-bottom: none;
            }
            span {
              font-size: 16px;
              color: var(--color-light-dark);
            }
            .image {
              display: inline-block;
              width: 25px;
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
  .cart-icon {
    display: inline-block;
    // margin: 0px 0px 0px 15px;
    cursor: pointer;
    position: relative;
    .counter {
      font-weight: normal;
      padding: 0;
      line-height: 16px;
      font-size: 11px;
      min-width: 16px;
      /* margin-top: -4px; */
      /* margin-right: 0; */
      /* margin-bottom: 0; */
      /* margin-left: -7px; */
      vertical-align: middle;
      text-align: center;
      border-radius: 50%;
      color: var(--color-white) !important;
      background: var(--color-dark-red);
      position: absolute;
      left: 7px;
      top: 3px;
      opacity: 1;
    }
  }
  .dropdown-menu.dropdown-menu-right.show {
    .private-dropdown-content {
      .dropdown-ads {
        a {
          display: block;
        }
      }
    }
  }
  .more-links {
    color: var(--color-white) !important;
    font-weight: 500;
    font-size: 15px;
    padding: 0 !important;
  }
}
.mobile-header {
  display: none;
}

@media (max-width: 1199px) {
  .desktop-header .collapse.navbar-collapse {
    width: calc(100% - 200px);
  }
  .desktop-header .collapse.navbar-collapse .ml-auto.navbar-nav {
    width: 100%;
    display: inline-block;
    text-align: right;
  }
  .desktop-header .collapse.navbar-collapse .ml-auto.navbar-nav li {
    display: inline-block;
  }
}
@media (max-width: 991px) {
  .header .column-2 {
    width: auto;
  }
  .header .col-md-6 {
    max-width: 100%;
    border: 0;
  }
  .d-inline-block.menu-links.dropdown:nth-child(6) .column-2.dropdown-menu.dropdown-menu-right,
  .d-inline-block.menu-links.dropdown:nth-child(5) .column-2.dropdown-menu.dropdown-menu-right {
    left: auto !important;
    right: 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  // .menu-links:nth-last-child(2).show .show {
  //   display: block !important;
  // }
  .custom-sell-btn .menu-links:last-child.show .show {
    display: block !important;
  }
  .menu-links:last-child.show .show {
    display: none !important;
  }
  .desktop-header {
    display: none;
  }
  .mobile-header {
    display: block;
  }
  .header nav.navbar .navbar-nav .menu-links a.nav-link span {
    display: inline-block;
    line-height: unset;
  }
  .header nav.navbar .navbar-nav .menu-links a.nav-link {
    padding: 10px 15px;
  }
  .header nav.navbar .navbar-nav .nav-item a.nav-link {
    padding: 10px 3px !important;
  }
  .navbar-toggler {
    border-color: var(--color-white) !important;
  }
  .header nav.navbar .navbar-nav {
    align-items: baseline !important;
    margin-top: 20px;
  }
  .header nav.navbar .navbar-nav .menu-links a.nav-link {
    color: var(--color-white);
  }
  .header .navbar-expand-md .navbar-nav .dropdown-menu {
    position: relative !important;
    top: 0px !important;
    background-color: transparent;
  }
  // .collapse.show.navbar-collapse {
  //   margin-top: 20px;
  // }
  .d-inline-block.menu-links.dropdown {
    width: 100%;
  }
  .dropdown-toggle::after {
    margin: 10px 0 0;
    float: right;
  }
  .custom-sell-btn .d-inline-block.menu-links.dropdown a.dropdown-toggle.nav-link {
    background-color: var(--color-secondary);
    padding: 10px 25px !important;
    width: 160px;
    color: white;
    border-radius: 8px;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
  .custom-sell-btn .cart-image {
    margin: 0;
    float: left;
  }
  .mobile-header a.navbar-brand {
    margin: 0;
  }
  .header nav.navbar .navbar-nav .menu-links .dropdown-menu.dropdown-menu-right {
    border-bottom: none;
  }
  .header nav.navbar .navbar-nav .menu-links:hover {
    background-color: transparent;
    border-color: transparent;
  }
  .header nav.navbar .navbar-nav .menu-links.show > a > span {
    color: var(--color-secondary) !important;
  }
  .header nav.navbar .navbar-nav .menu-links .dropdown-menu.dropdown-menu-right .dropdown-item .private-dropdown-content .dropdown-ads a {
    color: var(--color-white);
    line-height: 21px;
  }
  .dropdown-ads .image {
    display: inline-block;
    width: 25px;
    margin-right: 12px;
  }
  .custom-sell-btn .column-2.more-dropdown.dropdown-menu.dropdown-menu-right.show {
    border-radius: 0;
    border: none;
    width: 161px;
    margin: 0;
    left: 1px !important;
    border-bottom: 2px solid var(--color-secondary);
  }
  .custom-sell-btn button.dropdown-item {
    padding: 0;
  }
  .custom-sell-btn .dropdown-menu.dropdown-menu-right.show .private-dropdown-content .dropdown-ads a {
    padding: 10px 10px 10px 16px !important;
    border-bottom: 1px solid var(--border-color-primary);
    font-size: 16px;
    color: var(--color-light-dark);
    text-decoration: none;
  }
  .custom-sell-btn .dropdown-menu.dropdown-menu-right.show .private-dropdown-content .dropdown-ads a:last-child {
    border: 0;
  }
  .mobile-header .dropdown-item:focus,
  .mobile-header .dropdown-item:active {
    background-color: transparent !important;
  }
}
@media (max-width: 575px) {
  .mobile-header .navbar {
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .custom-sell-btn {
    order: 1;
    width: 100%;
    text-align: center;
  }
  .custom-sell-btn .d-inline-block.menu-links.dropdown a.dropdown-toggle.nav-link {
    margin: 30px auto 0;
  }
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.header nav.navbar .navbar-nav .menu-links .dropdown-menu.dropdown-menu-right {
  // animation: conditionalOpen 0.2s alternate;
  // transition: 0.4s linear;
}

// @keyframes conditionalOpen {
//   0% {
//     height: 0px;
//   }
//   100% {
//     height: auto;
//   }
// }
.anim-1 {
  color: #434343;
}
.anim-1 p {
  font-size: 12px;
}
.anim-1 h5 {
  margin-bottom: 14px;
  font-size: 16px;
}

.anim-1:hover {
  color: #f1833b;
}
.anim-1:hover svg {
  color: #f1833b;
}
.anim-1:hover p {
  margin-top: -10px;
  transition: 0.6s all ease;
}
// anim-2
.anim-2 {
  font-size: 20px;
  font-weight: 600;
  color: #434343;
  margin-top: 12px;
}
// .anim-2:hover{
//   text-align: center;
//   color: #f1833b;
//   transform: scale(0.9);
//   transition: 0.4s;
// }
.anim-3 img {
  width: 50px;
  height: 30px;
}

.anim-4 img {
  width: 50px;
  height: 30px;
}
// .anim-4:hover{
//   transform: scale(0.9);
//   color:#f1833b;
//   transition: 0.5s;
// }

/* Navigation Styles */
#header-main {
  ul li a {
    text-decoration: none;
  }
  nav {
    position: relative;
  }
  header.dark nav {
    background-color: rgba(255, 255, 255, 0.5);
  }
  header.light nav {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .main-nav {
    list-style-type: none;
    padding: 0px;
    font-size: 0px;
    max-width: 1000px;
    margin: 0 auto;
  }

  .main-nav > li {
    display: inline-block;
    padding: 0;
  }

  ul.main-nav > li > a {
    display: block;
    padding: 20px 30px;
    position: relative;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    box-sizing: border-box;
  }

  ul.main-nav > li:hover {
    background-color: #ffff;
  }
  ul.main-nav > li:hover > a {
    color: #333;
    font-weight: 400;
  }

  ul.main-nav > li ul.sub-menu-lists {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    display: block;
  }

  ul.main-nav > li ul.sub-menu-lists > li {
    padding: 2px 0;
  }

  ul.main-nav > li ul.sub-menu-lists > li > a {
    font-size: 14px;
  }

  .ic {
    position: fixed;
    cursor: pointer;
    display: inline-block;
    right: 25px;
    width: 32px;
    height: 24px;
    text-align: center;
    top: 0px;
    outline: none;
  }

  .ic.close {
    opacity: 0;
    font-size: 0px;
    font-weight: 300;
    color: #fff;
    top: 8px;
    height: 40px;
    display: block;
    outline: none;
  }

  /* Menu Icons for Devices*/
  .ic.menu {
    top: 25px;
    z-index: 20;
  }

  .ic.menu .line {
    height: 4px;
    width: 100%;
    display: block;
    margin-bottom: 6px;
  }
  .ic.menu .line-last-child {
    margin-bottom: 0px;
  }

  .sub-menu-head {
    margin: 10px 0;
  }
  .banners-area {
    margin-top: 20px;
    padding-top: 15px;
  }

  .banners-area img {
    width: 150px;
  }
  .nav-link-new {
    display: flex;
    margin-bottom: 10px;
    border-bottom: 1px solid #ececec;
    text-decoration: none;
    color: #000;
    a {
      text-decoration: none;
      color: #000;
      h5 {
        font-weight: 500;
        font-size: 14px;
      }
      &:hover {
        color: var(--orange);
        transition: 0.3s linear;
      }
    }
  }
  .new-li {
    height: 40px;
  }
}
@media only screen and (max-width: 768px) {
  #header-main {
    .sub-menu-head {
      color: orange;
    }
    .ic.menu {
      display: block;
    }
    header.dark .ic.menu .line {
      background-color: #fff;
    }
    header.light .ic.menu .line {
      background-color: #000;
    }
    .ic.menu .line {
      -webkit-transition: all 0.4s ease 0s;
      -o-transition: all 0.4s ease 0s;
      transition: all 0.4s ease 0s;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
    }
    .ic.menu:focus .line {
      background-color: #fff !important;
    }

    .ic.menu:focus .line:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .ic.menu:focus .line:nth-child(2) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      margin-top: -10px;
    }

    .ic.menu:focus .line:nth-child(3) {
      transform: translateY(15px);
      opacity: 0;
    }

    .ic.menu:focus {
      outline: none;
    }
    .ic.menu:focus ~ .ic.close {
      opacity: 1;
      z-index: 21;
      outline: none;
    }

    /*
  
  .ic.menu:focus ~ .ic.close { opacity: 1.0; z-index : 21;  }
  .ic.close:focus { opacity: 0; }
  */
    .ic.menu:hover,
    .ic.menu:focus {
      opacity: 1;
    }

    nav {
      background-color: transparent;
    }

    /* Main Menu for Handheld Devices  */
    ul.main-nav {
      z-index: 2;
      padding: 50px 0;
      position: fixed;
      right: 0px;
      top: 0px;
      width: 0px;
      background-color: rgba(0, 0, 0, 1);
      height: 100%;
      overflow: auto;
      /*CSS animation applied : Slide from Right*/
      -webkit-transition-property: background, width;
      -moz-transition-property: background, width;
      -o-transition-property: background, width;
      transition-property: background, width;
      -webkit-transition-duration: 0.6s;
      -moz-transition-duration: 0.6s;
      -o-transition-duration: 0.6s;
      transition-duration: 0.6s;
    }

    .ic.menu:focus ~ .main-nav {
      width: 300px;
      background-color: rgba(0, 0, 0, 1);
    }

    ul.main-nav > * {
      -webkit-transition-property: opacity;
      -moz-transition-property: opacity;
      -o-transition-property: opacity;
      transition-property: opacity;
      -webkit-transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      transition-duration: 0.4s;
      opacity: 0;
    }
    .ic.menu:focus ~ .main-nav > * {
      opacity: 1;
    }

    ul.main-nav > li > a:after {
      display: none;
    }
    ul.main-nav > li:first-child {
      border-radius: 0px;
    }
    ul.main-nav > li {
      display: block;
      border-bottom: 1px solid #444;
    }

    ul.main-nav > li > a {
      font-weight: 600;
    }

    ul.main-nav > li ul.sub-menu-lists > li a {
      color: #eee;
      font-size: 14px;
    }
    .sub-menu-head {
      font-size: 16px;
    }
    ul.main-nav > li:hover {
      background-color: transparent;
    }
    ul.main-nav > li:hover > a {
      color: #fff;
      text-decoration: none;
      font-weight: 600;
    }
    .sub-menu-block {
      border-left: 0px solid #ccc;
      border-right: 0px solid #ccc;
      border-bottom: 0px solid #ccc;
      position: relative;
      visibility: visible;
      opacity: 1;
      width: 800px !important;
    }

    .sub-menu-block {
      padding: 0 30px;
    }
    .banners-area {
      padding-bottom: 0px;
    }
    .banners-area div {
      margin-bottom: 15px;
    }
    .banners-area {
      border-top: 1px solid #444;
    }
  }
}

@media only screen and (min-width: 769px) {
  #header-main {
    .ic.menu {
      display: none;
    }
    /* Main Menu for Desktop Devices  */
    ul.main-nav {
      display: block;
      position: relative;
    }
    .sub-menu-block {
      padding: 15px;
    }

    /* Sub Menu */
    li > div.sub-menu-block {
      visibility: hidden;
      background-color: #fff;
      position: absolute;
      margin-top: 0px;

      color: #333;
      left: 0;
      box-sizing: border-box;
      z-index: 3;
      font-size: 16px;

      /*CSS animation applied for sub menu : Slide from Top */
      -webkit-transition: all 0.2s ease 0s;
      -o-transition: all 0.2s ease 0s;
      transition: all 0.4s ease 0s;
      -webkit-transform: rotateX(90deg);
      -moz-transform: rotateX(90deg);
      -ms-transform: rotateX(90deg);
      transform: rotateX(90deg);
      -webkit-transform-origin: top center;
      -ms-transform-origin: top center;
      transform-origin: top center;
    }

    ul.main-nav > li:hover > div.sub-menu-block {
      background-color: #fff;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.15);
      visibility: visible;
      opacity: 1;
      -webkit-transition: all 0.2s ease 0s;
      -o-transition: all 0.2s ease 0s;
      transition: all 0.4s ease 0s;
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }

    ul.main-nav > li > div.sub-menu-block > * {
      -webkit-transition-property: opacity;
      -moz-transition-property: opacity;
      -o-transition-property: opacity;
      transition-property: opacity;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      opacity: 0;
    }

    ul.main-nav > li:hover > div.sub-menu-block > * {
      opacity: 1;
    }

    .sub-menu-head {
      font-size: 20px;
    }

    /* List Separator: Outer Border */
    header.dark ul.main-nav > li > a {
      border-right: 1px solid #bbb;
    }
    header.light ul.main-nav > li > a {
      border-right: 1px solid #666;
    }

    /* List Separator: Inner Border */
    ul.main-nav > li > a:after {
      content: "";
      width: 1px;
      height: 62px;
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 2;
    }
    header.dark ul.main-nav > li > a:after {
      background-color: #777;
    }
    header.light ul.main-nav > li > a:after {
      background-color: #999;
    }

    /* Drop Down/Up Arrow for Mega Menu */
    ul.main-nav > li > a.mega-menu > span {
      display: block;
      vertical-align: middle;
    }
    ul.main-nav > li > a.mega-menu > span:after {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #fff;
      content: "";
      background-color: transparent;
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
    }

    ul.main-nav > li:hover > a.mega-menu span:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 0px solid transparent;
      border-bottom: 5px solid #666;
    }
    .banners-area {
      border-top: 1px solid #ccc;
    }
  }
}
