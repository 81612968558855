.left-menu-top-main {
  padding: 30px;
  background: var(--color-white);
  margin-bottom: 30px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.10);
  .left-top-status:last-child {
    margin-bottom: 0px;
  }
  .left-top-status {
    margin-bottom: 15px;
    button.btn {
      width: 100%;
      padding: 16px 20px 16px 15px;
      border: none;
      border-radius: 0;
      background: var(--color-background-all);
      .status-left {
        float: left;
        font-size: 14px;
        line-height: 14px;
        color: var(--color-text-gray);
      }
      .status-right {
        float: right;
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        color: var(--color-secondary);
      }
    }
    &.active {
      button.btn {
        background: var(--color-primary);
      }
      .status-left {
        color: var(--color-white) !important;
      }
      .status-right {
        color: var(--color-white) !important;
      }
    }
  }  
}
