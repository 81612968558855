.home-content {
  margin: -85px 0 0 0;
  #loader {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .web-view {
    display: none !important;
  }
  #home-mobile-banner {
    padding: 15px;
    .react-autosuggest__input {
      border: 0 !important;
      width: 270px;
    }
    .react-autosuggest__input--focused {
      outline: none !important;
    }
    .react-autosuggest__suggestions-container--open {
      width: 310px;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border: 0;
    }
    #home-mobile-tabs {
      margin-top: 20px;
      .tabshome-btn{
        display: flex;
        margin-bottom: 25px;

      }
      .filter-btns {
          overflow: auto;
        .btn-vehicle {
          position: relative;
          border-radius: 0;
          text-decoration: none;
          transition: .2s;
          background: #f68301;
          margin: 0px 2px;
          border-radius: 0px;
          padding: 13px 10px;
          font-size: 16px;
          color: #fff;
          border:0;
          display: flex;
          align-items: center;
          flex: 1;
          line-height: 18px;
          text-align: center;
          &:focus {
            box-shadow: none !important;
          }
          
          img,i{
            margin-right: 4px;
          }
        }
        .active-btn {
          // border-bottom: 2px solid #f68301;
          // color: #000;
          background: #063c6c;
          &::after{
            content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
          }
        }
      }
      .mobile-input-search {
        display: flex;
        border: 1px solid #ccc;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 8px;
        border-radius: 4px;
        &:focus-within {
          border: 1px solid var(--orange);
        }
        .home-mobile-input {
          border: 0 !important;
          &:focus {
            box-shadow: none !important;
            border: 0 !important;
          }
        }
        .mobile-icon {
          align-self: center;
          padding-right: 10px;
          color: var(--orange);
        }
      }
    }
    .used-new-car-mobile-btns {
      // border: 1px solid #cecece;
      // border-radius: 12px;
      // margin-top: 0px;
      .custom-mobile-btn {
        border: 1px solid #ccc;
        padding: 12px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        color: #000;
        width: 33.33%;
        font-size: 14px;
        white-space: nowrap;
        &:focus {
          box-shadow: none !important;
        }
      }
      .active-btn {
        background-color: var(--orange);
        color: white;
      }
    }
  }
}

@media (min-width: 768px) {
  .mobile-view {
    display: none !important;
  }
}
