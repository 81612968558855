// .home-content {
//   margin: -85px 0 0 0;
//   #loader {
//     display: none !important;
//   }
// }
:root {
  --orange: #f68300;
  --lightgrey: #ececec;
  --primary: #023047ed;
  --darkgrey: #707070;
}

.Homepage {
  .css-1pahdxg-control {
    width: 100% !important;
    border-radius: 4px;
    border: 1px solid orange !important;
    box-shadow: none !important;
    min-height: 45px;
  }
  div.horizontal-form-box1{
    background-color: #fff;
    max-width: 1000px;
    width: 100%;
  }
  .jumbotron {
    // background: linear-gradient(rgba(29, 84, 107, 0.33), #023047ed),
    //   url("../../../assets/Images/banner_Image.jpeg");
    // background-size: cover;
    // background-position: center;
    border-radius: 0;
    min-height: calc(100vh - 400px);
    margin-bottom: 0;
    background-color: #143f54;
    padding-left: 0;
    padding-right: 0;
  }

  .main-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 12px #6d8dad29;
    opacity: 1;
    border-radius: 14px;

    .react-autosuggest__container input.react-autosuggest__input {
      font-size: 16px;
      padding: 10px;
      border: 1px solid #c3c3c3;
      border-radius: 10px;
    }

    .react-autosuggest__container {
      width: 91%;
      margin: 5px 17px 15px 20px;

      input.react-autosuggest__input {
        width: 100%;
        height: 100%;
        padding-left: 8px;

        &:focus {
          outline: none;
          border: 1px solid var(--orange);
        }
      }
    }

    .all-cities {
      .css-2b097c-container {
        margin: 0px 20px;

        .css-g1d714-ValueContainer {
          width: 365px;
        }

        .css-yk16xz-control {
          width: 365px;
          margin-top: 0px;
          margin-bottom: 1px;
        }
      }
    }

    .min-max {
      .css-2b097c-container {
        margin: 0px 20px;

        .css-yk16xz-control {
          width: 365px;
          margin-top: 0px;
          margin-bottom: 13px;
        }
      }
    }

    .css-2b097c-container {
      margin: 0px 20px;

      .css-g1d714-ValueContainer {}

      .css-yk16xz-control {
        width: 365px;
        margin-top: 15px;
        margin-bottom: 13px;
      }
    }

    .input-group-prepend {
      z-index: 0;
      margin: 3px 20px;
      width: 100%;

      .link-search {
        //   @include buttons-link-secondary;
        border-color: var(--color-secondary);
        border-radius: 10px;
        justify-content: center;
        vertical-align: middle;
        display: inline-flex;
        flex-direction: column;
        padding: 0 10px;
        height: 40px;
        width: 100%;
        color: white;
        font-weight: 600;
        background-color: var(--orange);

        &:hover {
          text-decoration: none;
        }
      }
    }

    .nav.nav-tabs {
      background-color: var(--lightgrey);
      color: var(--darkgrey);
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      cursor: pointer;

      .nav-item {
        // border-top-left-radius: 12px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 12px;
        border-right: 1px solid rgb(182, 182, 182);
        border-bottom: 1px solid rgb(182, 182, 182);
        font-size: 15px;
      }

      .nav-link {
        padding: 15px 17.68px;
        border-top-left-radius: 12px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 12px;
        font-size: 15px;
        // width: 143.6px;
        color: grey !important;

        &:hover {
          background-color: var(--orange);
          margin-left: -0.3px !important;
          border-left: none !important;
          border: 1px solid var(--orange) !important;
          transition: 0.3s all;
          color: #fff !important;

          img {
            filter: brightness(0) invert(1);
          }
        }
      }

      .nav-item.active {
        background-color: var(--orange);
        color: white !important;
      }

      .nav-link.active {
        background-color: var(--orange);
        color: white !important;
        border-color: transparent !important;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  .heading {
    align-self: center;

    h1 {
      line-height: 40px;
      font-weight: 800;
      color: white;
      font-size: 45px;
    }

    p {
      text-align: start;
      color: white;
      font-size: 22px;
      margin-top: 10px;
      line-height: normal;
    }
  }

  .custom-btn {
    background-color: var(--orange) !important;
    color: white;
    width: 100%;
    border-radius: 10px;
    font-weight: 600;
    box-shadow: 0px 15px 14px #6d8dad2e;
    padding: 9px;

    &:hover {
      opacity: 0.7;
    }
  }

  .tabs-space {
    padding: 20px 30px;
  }

  // react select
  .css-yk16xz-control {
    border-radius: 4px;
  }

  .form-control {
    border-radius: 4px;
  }

  .tab-pane {
    padding: 15px;
  }

  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }

  .react-autosuggest__container .react-autosuggest__suggestions-container--open {
    cursor: pointer;
  }

  .react-autosuggest__container .react-autosuggest__suggestion {
    padding: 4px 10px;

    &:hover {
      background-color: var(--orange);
      color: white;
      border-radius: 6px;
    }
  }

  // -----------------
  .css-26l3qy-menu .css-4ljt47-MenuList #react-select-2-option-0 {
    background-color: var(--orange);
    color: white;
  }

  .css-26l3qy-menu .css-4ljt47-MenuList #react-select-3-option-0 {
    background-color: var(--orange);
    color: white;
  }

  .css-26l3qy-menu .css-4ljt47-MenuList #react-select-4-option-0 {
    background-color: var(--orange);
    color: white;
  }

  .css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option {
    &:hover {
      background-color: var(--orange);
      color: white;
    }
  }
}

//   responsive

@media (max-width: 1200px) {
  .Homepage {
    .heading {
      align-self: center;

      h1 {
        line-height: 28px;
        font-weight: 800;
        color: white;
        font-size: 30px;
      }

      p {
        text-align: center;
        padding-left: 0px;
        color: white;
        font-size: 18px;
      }
    }

    .main-card {
      // .nav.nav-tabs{
      //     .nav-link{
      //         width: 118px;
      //     }
      // }
    }
  }
}

// responsive

@media (max-width: 1100px) {
  .Homepage {
    .main-card {
      .react-autosuggest__container {
        width: 87.5%;
        margin: 5px 17px 15px 20px;

        input.react-autosuggest__input {
          width: 100%;
          height: 100%;
          padding-left: 8px;

          &:focus {
            outline: none;
            border: 1px solid var(--orange);
          }
        }
      }

      .all-cities {
        .css-2b097c-container {
          .css-g1d714-ValueContainer {
            width: 280px;
          }
        }
      }

      .min-max {
        .css-2b097c-container {
          .css-g1d714-ValueContainer {
            width: 280px;
          }
        }
      }

      .css-2b097c-container {
        .css-g1d714-ValueContainer {
          width: 280px;
        }
      }

      // .nav.nav-tabs{
      //     .nav-link{
      //         width: 118px;
      //     }
      // }
    }
  }
}

@media (max-width: 768px) {
  .Homepage {
    .heading {
      align-self: center;

      h1 {
        text-align: center;
        margin-top: 20px;
        line-height: 28px;
        font-weight: 800;
        color: white;
        font-size: 30px;
      }

      p {
        text-align: center;
        padding-left: 0px;
        color: white;
        font-size: 18px;
      }
    }

    .main-card {
      .all-cities {
        .css-2b097c-container {
          .css-g1d714-ValueContainer {
            width: 565px;
          }
        }
      }

      .min-max {
        .css-2b097c-container {
          .css-g1d714-ValueContainer {
            width: 565px;
          }
        }
      }

      .css-2b097c-container {
        .css-g1d714-ValueContainer {
          width: 565px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .Homepage {
    .heading {
      align-self: center;

      h1 {
        text-align: center;
        margin-top: 20px;
        line-height: 28px;
        font-weight: 800;
        color: white;
        font-size: 22px;
      }

      p {
        text-align: center;
        padding-left: 0px;
        color: white;
        font-size: 18px;
      }
    }

    .main-card {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 12px #6d8dad29;
      opacity: 1;
      border-radius: 12px;

      .react-autosuggest__container input.react-autosuggest__input {
        font-size: 16px;
        padding: 10px;
        border: 1px solid #c3c3c3;
        border-radius: 10px;
      }

      .react-autosuggest__container {
        width: 91%;
        margin: 5px 17px 15px 20px;

        input.react-autosuggest__input {
          width: 100%;
          height: 100%;
          padding-left: 8px;

          &:focus {
            outline: none;
            border: 1px solid var(--orange);
          }
        }
      }

      .all-cities {
        .css-2b097c-container {
          margin: 0px 20px;

          .css-g1d714-ValueContainer {
            width: 280px;
          }

          .css-yk16xz-control {
            width: 280px;
            margin-top: 0px;
            margin-bottom: 1px;
          }
        }
      }

      .min-max {
        .css-2b097c-container {
          margin: 0px 20px;

          .css-yk16xz-control {
            width: 280px;
            margin-top: 0px;
            margin-bottom: 13px;
          }
        }
      }

      .css-2b097c-container {
        margin: 0px 20px;

        .css-g1d714-ValueContainer {}

        .css-yk16xz-control {
          width: 280px;
          margin-top: 15px;
          margin-bottom: 13px;
        }
      }

      .input-group-prepend {
        z-index: 0;
        margin: 3px 20px;
        width: 100%;

        .link-search {
          //   @include buttons-link-secondary;
          border-color: var(--color-secondary);
          border-radius: 10px;
          justify-content: center;
          vertical-align: middle;
          display: inline-flex;
          flex-direction: column;
          padding: 0 10px;
          height: 40px;
          width: 100%;
          color: white;
          font-weight: 600;
          background-color: var(--orange);

          &:hover {
            text-decoration: none;
          }
        }
      }

      .nav.nav-tabs {
        background-color: var(--lightgrey);
        color: var(--darkgrey);
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        cursor: pointer;

        .nav-item {
          // border-top-left-radius: 12px;
          border-bottom-right-radius: 0;
          border-top-right-radius: 12px;
          border-right: 1px solid rgb(182, 182, 182);
          border-bottom: 1px solid rgb(182, 182, 182);
          font-size: 15px;
        }

        .nav-link {
          padding: 15px 17.68px;
          border-top-left-radius: 12px;
          border-bottom-right-radius: 0;
          border-top-right-radius: 12px;
          font-size: 15px;
          // width: 143.6px;
        }

        .nav-item.active {
          background-color: var(--orange);
        }

        .nav-link.active {
          background-color: var(--orange);
          color: white;
          border-color: transparent !important;

          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .Homepage {
    .main-card {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 12px #6d8dad29;
      opacity: 1;
      border-radius: 12px;

      .react-autosuggest__container input.react-autosuggest__input {
        font-size: 16px;
        padding: 10px;
        border: 1px solid #c3c3c3;
        border-radius: 10px;
      }

      .react-autosuggest__container {
        width: 91%;
        margin: 5px 17px 15px 20px;

        input.react-autosuggest__input {
          width: 100%;
          height: 100%;
          padding-left: 8px;

          &:focus {
            outline: none;
            border: 1px solid var(--orange);
          }
        }
      }

      .all-cities {
        .css-2b097c-container {
          margin: 0px 20px;

          .css-g1d714-ValueContainer {
            width: 233px;
          }

          .css-yk16xz-control {
            width: 233px;
            margin-top: 0px;
            margin-bottom: 1px;
          }
        }
      }

      .min-max {
        .css-2b097c-container {
          margin: 0px 20px;

          .css-yk16xz-control {
            width: 233px;
            margin-top: 0px;
            margin-bottom: 13px;
          }
        }
      }

      .css-2b097c-container {
        margin: 0px 20px;

        .css-g1d714-ValueContainer {}

        .css-yk16xz-control {
          width: 233px;
          margin-top: 15px;
          margin-bottom: 13px;
        }
      }

      .input-group-prepend {
        z-index: 0;
        margin: 3px 20px;
        width: 100%;

        .link-search {
          //   @include buttons-link-secondary;
          border-color: var(--color-secondary);
          border-radius: 10px;
          justify-content: center;
          vertical-align: middle;
          display: inline-flex;
          flex-direction: column;
          padding: 0 10px;
          height: 40px;
          width: 100%;
          color: white;
          font-weight: 600;
          background-color: var(--orange);

          &:hover {
            text-decoration: none;
          }
        }
      }

      .nav.nav-tabs {
        background-color: var(--lightgrey);
        color: var(--darkgrey);
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        cursor: pointer;

        .nav-item {
          // border-top-left-radius: 12px;
          border-bottom-right-radius: 0;
          border-top-right-radius: 12px;
          border-right: 1px solid rgb(182, 182, 182);
          border-bottom: 1px solid rgb(182, 182, 182);
          font-size: 15px;
        }

        .nav-link {
          padding: 15px 17.68px;
          border-top-left-radius: 12px;
          border-bottom-right-radius: 0;
          border-top-right-radius: 12px;
          font-size: 15px;
          // width: 143.6px;
        }

        .nav-item.active {
          background-color: var(--orange);
        }

        .nav-link.active {
          background-color: var(--orange);
          color: white;
          border-color: transparent !important;

          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .Homepage {
    .main-card {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 12px #6d8dad29;
      opacity: 1;
      border-radius: 12px;

      .react-autosuggest__container input.react-autosuggest__input {
        font-size: 16px;
        padding: 10px;
        border: 1px solid #c3c3c3;
        border-radius: 10px;
      }

      .react-autosuggest__container {
        width: 91%;
        margin: 5px 17px 15px 20px;

        input.react-autosuggest__input {
          width: 100%;
          height: 100%;
          padding-left: 8px;

          &:focus {
            outline: none;
            border: 1px solid var(--orange);
          }
        }
      }

      .all-cities {
        .css-2b097c-container {
          margin: 0px 20px;

          .css-g1d714-ValueContainer {
            width: 250px;
          }

          .css-yk16xz-control {
            width: 250px;
            margin-top: 0px;
            margin-bottom: 1px;
          }
        }
      }

      .min-max {
        .css-2b097c-container {
          margin: 0px 20px;

          .css-yk16xz-control {
            width: 250px;
            margin-top: 0px;
            margin-bottom: 13px;
          }
        }
      }

      .css-2b097c-container {
        margin: 0px 20px;

        .css-g1d714-ValueContainer {}

        .css-yk16xz-control {
          width: 250px;
          margin-top: 15px;
          margin-bottom: 13px;
        }
      }

      .input-group-prepend {
        z-index: 0;
        margin: 3px 20px;
        width: 100%;

        .link-search {
          //   @include buttons-link-secondary;
          border-color: var(--color-secondary);
          border-radius: 10px;
          justify-content: center;
          vertical-align: middle;
          display: inline-flex;
          flex-direction: column;
          padding: 0 10px;
          height: 40px;
          width: 100%;
          color: white;
          font-weight: 600;
          background-color: var(--orange);

          &:hover {
            text-decoration: none;
          }
        }
      }

      .nav.nav-tabs {
        background-color: var(--lightgrey);
        color: var(--darkgrey);
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        cursor: pointer;

        .nav-item {
          // border-top-left-radius: 12px;
          border-bottom-right-radius: 0;
          border-top-right-radius: 12px;
          border-right: 1px solid rgb(182, 182, 182);
          border-bottom: 1px solid rgb(182, 182, 182);
          font-size: 15px;
        }

        .nav-link {
          padding: 15px 17.68px;
          border-top-left-radius: 12px;
          border-bottom-right-radius: 0;
          border-top-right-radius: 12px;
          font-size: 15px;
          // width: 143.6px;
        }

        .nav-item.active {
          background-color: var(--orange);
        }

        .nav-link.active {
          background-color: var(--orange);
          color: white;
          border-color: transparent !important;

          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}


.homepage-form {
  .container {
    margin-top: -177px;
  }

  .css-yk16xz-control {
    min-height: 45px;
  }

  .aucSheet {
    input {
      max-width: 100%;

    }
  }

  .search-inputs {
    .react-autosuggest__container {
      width: 100%;

      input.react-autosuggest__input {
        border: 1px solid #c3c3c3;
        border-radius: 4px;
        font-size: 16px;
        padding: 10px;
        width: 100%;

        &:focus {
          border: 1px solid #f68300;
          outline: none;
        }
      }
    }
  }
}
