.rides-verticle-item-main {
    border-radius: 0;
    border: 1px solid var(--border-color-primary);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    margin: 0 0 30px 0;

    .product-img {
        height: 200px;
        width: 100%;
        .card-img-top {
            width: 100%;
            height: 100%;
            object-fit: fill;
            object-position: center;
            border-radius: 0;
        }
        :hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }

    .product-label {
        position: absolute;
        top: 0;
        right: 20px;
        padding: 15px 0px 0px 0px;

        span {
            color: var(--color-white);
            padding: 0px 15px 0px 0px;
        }
    }

    .product-item-body {
        padding: 12px;

        h6 {
            .card-title {
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100% !important;
                max-width: 200px !important;
                /* height: 1.2em; */
                margin: 0;
                display: inline-block;
                white-space: nowrap;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: var(--text-color-primary);
                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
                span {
                    color: var(--color-text-gray);
                    padding: 0px 10px 0px 0px;
                }
            }

            margin: 0;
            padding: 0;

            span.favourit {
                float: right;
                font-size: 20px;
                color: var(--color-secondary);
                cursor: pointer;
            }
        }

        .condition {
            font-size: 12px;
            font-weight: 400;
            color: var(--color-text-gray);
            margin: 0px;
            padding: 6px 0 0 0;
            display: inline-block;
            vertical-align: top;
            width: 100%;
            line-height: 20px;

            span {
                border-right: 1px solid #c1c1c1;
                padding-right: 8px;
                margin-right: 8px;
                float: left;

                div:first-child {
                    display: inline-block;

                    svg {
                        margin: 0 5px 0 0;
                    }
                }

                &:last-child {
                    border: none;
                    margin-right: 0;
                    padding-right: 0;
                }

                img {
                    margin: 0 5px 0 0;
                }
            }
        }

        .product-price {
            padding: 10px 0px 10px 0px;
            color: var(--color-secondary);
            font-weight: 700;
            border-bottom: 1px solid var(--border-color-primary);
        }

        .footer-rating {
            padding: 10px 0px 0px 0px;

            span {
                float: right;
                color: var(--color-primary);
                font-weight: 700;
                font-size: 14px;
                line-height: 30px;
            }
        }
    }
}
@media only screen and (max-width: 991px) and (min-width: 768px){
    .rides-verticle-item-main .product-item-body h6 .card-title {
        max-width: 148px !important;
    }
    .rides-verticle-item-main .product-item-body .footer-rating span {
        float: none;
        display: block;
    }
}
@media only screen and (max-width: 767px) {
    .rides-verticle-item-main .product-item-body h6 .card-title {
        max-width: 200px !important;
    }
}
