.my-rides-main {
  .rides-main-right {
    padding: 25px 25px;
    background: var(--color-white);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    height: 100%;
    .rides-main-right-content {
      border: 1px solid var(--border-color-primary);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      margin: 0 0 20px 0;
      .rides-image {
        height: 150px;
        width: 100%;
        display: inline-block;
        vertical-align: top;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
      .rides-content {
        text-align: center;
        padding: 20px 0px 20px 0px;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        p {
          font-size: 17px;
          font-weight: 500;
          color: var(--text-color-primary);
          line-height: 20px;
          margin-bottom: 10px;
          span {
            color: var(--color-text-gray);
            font-weight: 400;
            line-height: 21px;
            margin-right: 2px;
          }
        }
        .rides-ratings {
          display: inline-block;
          vertical-align: top;
          line-height: 16px;
          i {
            color: #f5d21a;
            margin-right: 5px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .rides-buttons {
      button {
        margin: 10px 0px 0px 0px;
        font-size: 12px;
        font-weight: 500;
        color: var(--color-white);
        border-radius: 0px;
        padding: 0;
        border: none;
        height: 30px;
        line-height: 30px;
      }
      .reset {
        background: var(--color-primary);
        margin-right: 10px;
        width: 100px;
        &:hover {
          background: var(--color-secondary);
        }
      }
      .new-ride {
        background: var(--color-secondary);
        width: 130px;
        &:hover {
          background: var(--color-primary);
        }
      }
      i,
      svg {
        margin: 0px 8px 0px 0px;
      }
    }
    .listing-loadmore {
      text-align: center;
    }
  }
}
