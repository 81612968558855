h1 {
  font-size: 40px;
}
h2 {
  font-size: 30px;
  line-height: 45px;
  font-weight: 700;
}
h3 {
  font-size: 26px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
.form-group {
  position: relative;
}
.pagination.active {
  background-color: red;
  color: yellow !important;
}
.pagination {
  display: inline-flex;
  background: white;
  .active {
    // background: var(--color-secondary);
    color: var(--color-secondary) !important;
  }
  li {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    a {
      font-size: 20px;
      // color: rgb(160, 160, 160) !important;
      cursor: pointer;
      padding: 10px;
    }
  }
}
.field-fade {
  opacity: 0.5 !important;
}

@mixin buttons-link-primary {
  background-color: var(--color-primary);
  text-align: center;
  color: var(--color-white);
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  &:hover {
    background-color: var(--color-secondary);
  }
}

@mixin buttons-link-secondary {
  background-color: var(--color-secondary);
  text-align: center;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  cursor: pointer;
  &:hover {
    background-color: var(--color-primary);
  }
}

@mixin a-links-primary {
  color: var(--color-primary);
  &:hover {
    color: var(--color-secondary);
  }
}
@mixin a-links-secondary {
  color: var(--color-secondary);
  &:hover {
    color: var(--color-primary);
  }
}

.psw-show-icon {
  position: relative;
  i {
    position: absolute;
    z-index: 1000;
    top: 26.5px;
    padding: 19px 10px;
    right: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}

.filter-popover {
  // .popover {
  //   min-width: 400px;
  //   min-height: 300px;
  //   max-height: 300px;

  //   .popover-inner {
  //     min-width: 400px;
  //     .popover-body {
  //       overflow: hidden;
  //       overflow-y: scroll;
  //       max-height: 260px;
  //     }

  //     .checkbox-list {
  //       max-height: 360px;

  //       .list-links {
  //         font-size: 13px;
  //         margin-bottom: 20px;

  //         li {
  //           width: 50%;
  //           padding: 0 10px;
  //           float: left;

  //           .checkbox {
  //             cursor: default;
  //             font-size: 12px;
  //             margin-bottom: 0;
  //             width: 100%;

  //             input[type="checkbox"] {
  //               vertical-align: -2px;
  //               margin: 0;
  //               margin-right: 5px;
  //               position: relative;
  //             }

  //             .badge-gray-light {
  //               font-size: 11px;
  //               background: #dedede;
  //               color: #27324e;
  //               font-weight: normal;
  //             }

  //             .pull-right {
  //               margin-left: 20px;
  //               float: right;
  //             }
  //           }
  //         }
  //       }

  //       .list-unstyled {
  //         padding-left: 0;
  //         list-style: none;
  //       }
  //     }
  //   }
  // }
  .popover {
    max-width: 800px !important;
    max-height: 600px !important;
    min-width: 800px !important;
    overflow: scroll;
    left: 20px !important;
    padding: 15px 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2) !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 0;
    top: -70px !important;
  }

  .btn-clear {
    padding: 0px;
    font-size: 13px;
    float: right;
  }
  .arrow {
    background-color: red !important;
  }
}

.css-yk16xz-control {
  cursor: pointer !important;
}
.css-oq1nxl-control {
  cursor: pointer !important;
}
.custom-pointer-cursor-class {
  cursor: pointer !important;
}
@media (max-width: 1199px) {
  .container {
    max-width: 1140px !important;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 25px !important;
    line-height: 35px !important;
    font-weight: 700 !important;
  }
  h3 {
    font-size: 20px !important;
  }
  h4 {
    font-size: 15px !important;
  }
  h5 {
    font-size: 14px !important;
  }
  h6 {
    font-size: 12px !important;
  }
  p {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}
.react-autosuggest__container {
  position: relative;
  .react-autosuggest__suggestions-container--open {
    position: absolute;
    width: 100%;
    background: var(--color-white);
    border: 1px solid var(--border-color-primary);
    text-align: left;
    z-index: 1;
    max-height: 200px;
    // min-height: 50px;
    overflow: hidden;
    padding: 5px 10px;
    overflow-y: scroll;
  }
}

.free-ship {
  color: var(--color-success) !important;
}
span.option-text {
  width: 95px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}
.featured-section .card.product-item .product-label {
  display: none;
}
