.ads-category-tabs {
  background: var(--color-white);
  vertical-align: top;
  margin-bottom: 20px;
  ul.nav-tabs.nav.nav-tabs {
    .tab-items-1 {
      .nav-link {
        border-radius: 10px;
      }
    }
    .tab-items-2 {
      .nav-link {
        border-radius: 10px;
      }
    }
    padding: 0;
    border: none;
    li {
      margin: 0;
      width: 49%;
      margin-right: 0%;

      &:last-child {
        margin: 0;
      }
      a {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        padding: 14px 20px;
        text-align: center;
        width: 100%;
        color: var(--color-text-gray);
        cursor: pointer;
        background: white;
        color: var(--color-text-gray);
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        &.active.nav-link,
        &:hover {
          background: var(--orange);
          transition: 0.3s;
          color: white;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .ads-category-tabs {
    margin-bottom: 5px !important;
    ul.nav-tabs.nav.nav-tabs {
      li {
        a {
          padding: 10px !important;
          font-size: 16px;
        }
      }
    }
  }
}
